<app-header title="Reward checkout"></app-header>
<!-- <div
  bsModal
  #editModal="bs-modal"
  class="modal fade px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body pt-3">
        <div class="dialog-container">
          <img
            class="image mr14"
            src="../../../../assets/images/Pediasure Image.JPG"
            alt="Pediasure"
          />

          <div>
            <h5 class="font-blue">Pediasure GOLD Vanilla 850g</h5>
            <div class="input-container">
              <div>
                <label class="grey">Number</label>
                <select
                  class="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option value="1">1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-checkout">
          <button type="button" class="btn btn-primary">Update</button>
          <button type="button" class="btn btn-primary">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div
  bsModal
  #editModal="bs-modal"
  class="modal fade px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="product-qty row">
          <div class="col-4">
            <img [src]="rewardInfo.image" class="w-100" alt="Product" />
          </div>
          <div class="col-8 brand-qty pt-0">
            <p class="font-darkblue">
              {{ rewardInfo.name }}
            </p>
            <div class="quantity">
              <p class="fs13 color-lightgrey mb-1">Number:</p>
              <div class="position-relative">
                <input
                  disabled
                  type="number"
                  value="{{ count }}"
                  name="qty"
                  class="w-100 fs13 qty"
                />
                <img
                  class="increment"
                  (click)="onIncrement(count)"
                  src="../../../../assets/images/arrow-up.png"
                />
                <img
                  class="decrement"
                  [class.disabled]="disabled === true"
                  (click)="onDecrement(count)"
                  src="../../../../assets/images/arrow-down.png"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <button
              type="submit"
              class="primaryBtn mt-3 fW500 Btn"
              (click)="onClickUpdateQuantity(count)"
            >
              Update
            </button>
            <button
              type="submit"
              (click)="onClickClose()"
              class="primaryBtn btn-white my-2 fW500 Btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container globalContainer px30">
  <div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header my-4">
      <div class="step" data-target="#test-l-1">
        <button class="step-trigger" [disabled]="reviewDisabled">
          <span class="bs-stepper-circle" *ngIf="!reviewDisabled"
            ><img src="../../assets/images/Group 5104.svg" alt="cart"
          /></span>
          <span class="bs-stepper-circle" *ngIf="reviewDisabled"
            ><img src="../../assets/images/Group_cart_empty.svg" alt="cart"
          /></span>
          <span class="bs-stepper-label">Review</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#test-l-2">
        <button class="step-trigger" [disabled]="deliveryDisabled">
          <span class="bs-stepper-circle" *ngIf="deliveryDisabled"
            ><img src="../../assets/images/Group 5105.svg"
          /></span>
          <span class="bs-stepper-circle" *ngIf="!deliveryDisabled"
            ><img src="../../assets/images/Group_5105_1.svg"
          /></span>

          <span class="bs-stepper-label">Delivery</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#test-l-3">
        <button class="step-trigger" [disabled]="confirmationDisabled">
          <span class="bs-stepper-circle" *ngIf="confirmationDisabled"
            ><img src="../../assets/images/Group 5106.svg"
          /></span>
          <span class="bs-stepper-circle" *ngIf="!confirmationDisabled"
            ><img src="../../assets/images/Group_5106_1.svg"
          /></span>
          <span class="bs-stepper-label">Confirmation</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div id="test-l-1" class="content">
        <section class="mb-5 rewards-checkout step-1">
          <div class="product-card m-1">
            <span class="font-blue cursor-pointer">{{ count }} x</span>
            <img [src]="rewardInfo.image" class="image" alt="product" />
            <div class="">
              <div class="product-name">
                <h4 class="fs16 font-darkblue">{{ rewardInfo.name }}</h4>
                <h4 class="fs16 font-darkblue">
                  {{ rewardInfo.intouchPoints }} Pts
                </h4>
              </div>
              <div class="product-actions">
                <span
                  class="font-blue cursor-pointer fW600"
                  (click)="onClickEdit()"
                >
                  EDIT
                </span>
                <div
                  class="color-lightgrey cursor-pointer remove"
                  (click)="onClickRemove()"
                >
                  Remove
                </div>
              </div>
            </div>
            <!-- <div class="product-name">
              <h4 class="fs16 font-darkblue">{{ rewardInfo.name }}</h4>
              <span class="font-blue cursor-pointer" (click)="onClickEdit()"
                >EDIT</span
              >
            </div>
            <div class="product-points">
              <h4>{{ rewardInfo.intouchPoints }} Pts</h4>

              <span
                class="color-lightgrey cursor-pointer"
                (click)="onClickRemove()"
                >Remove</span
              >
            </div> -->
          </div>
          <div class="points-used my-4 text-right">
            <p class="mb-2">Total points to be used</p>
            <h4 class="fs24 font-darkblue fW600">
              {{ count * rewardInfo.intouchPoints }} pts
            </h4>
          </div>
          <button
            type="submit"
            class="primaryBtn my-3 fW500 Btn"
            (click)="onClickProceedToDelivery()"
          >
            Proceed to delivery
          </button>
        </section>
      </div>
      <div id="test-l-2" class="content">
        <section class="signup-container px-0">
          <h5 class="fs20">Shipping information</h5>

          <div class="address-card mx-1 mt-4 mb-2" *ngIf="!showAddressForm">
            <img src="../../assets/images/location.png" alt="Location" />
            <div class="address-details">
              <h5 class="fs16 mb-1">{{ addressData.recipientName }}</h5>
              <p class="fs13 font-blue mb-2">
                +65 {{ addressData.phoneNumber }}
              </p>
              <p class="address-field fs13 w-100 mb-0">
                {{ addressData.address }}
              </p>
              <p class="address-field fs13 w-100 mb-0">
                {{ addressData.unitnumber }}
              </p>
              <p class="address-field fs13 w-100 mb-0">
                {{ addressData.postalCode }}
              </p>
            </div>
            <div class="editBtn pr-0">
              <button
                type="button"
                class="btn v-top px-0 py-0 font-blue fs13"
                (click)="onClickEditAddress()"
              >
                EDIT
              </button>
            </div>
          </div>

          <button
            class="primaryBtn Btn mt40 mb-5"
            type="button"
            *ngIf="!showAddressForm"
            (click)="onClickConfirmredeemption()"
          >
            Confirm redemption
            <img
              src="../../assets/images/arrow-right-white.png"
              class="v-sub mx-2"
            />
          </button>

          <form
            *ngIf="showAddressForm"
            [formGroup]="addressForm"
            class="signup-Form mt-4"
            (ngSubmit)="onSubmitAddressForm()"
          >
            <div class="position-relative">
              <div class="form-group mt20">
                <label>Recipient name</label>
                <input
                  type="text"
                  formControlName="recipientName"
                  class="form-control"
                  placeholder="Enter your name"
                  [ngClass]="{
                    'is-invalid':
                      submittedAddressForm &&
                      addressFormFormVal.recipientName.errors
                  }"
                />
              </div>
            </div>
            <div
              *ngIf="
                submittedAddressForm && addressFormFormVal.recipientName.errors
              "
              class="error"
            >
              <span *ngIf="addressFormFormVal.recipientName.errors.required"
                >Please enter recipient name</span
              >
            </div>
            <div class="position-relative">
              <div class="form-group mt20">
                <label>Recipient mobile number</label>
                <input
                  type="number"
                  formControlName="recipientMobile"
                  class="form-control mobNumber"
                  placeholder="9xx-xxxx-xxxx"
                  [ngClass]="{
                    'is-invalid':
                      submittedAddressForm &&
                      addressFormFormVal.recipientMobile.errors
                  }"
                />
                <span class="mobPlaceholder"
                  ><img src="../../../assets/images/flag.png" />+65
                  <img src="../../../assets/images/down-arrow.png" />
                </span>
                <img
                  *ngIf="addressFormFormVal.recipientMobile.status == 'VALID'"
                  class="correct-icon"
                  src="../../../assets/images/Group 4868.svg"
                />
              </div>
            </div>

            <div
              *ngIf="
                submittedAddressForm &&
                addressFormFormVal.recipientMobile.errors
              "
              class="error"
            >
              <span *ngIf="addressFormFormVal.recipientMobile.errors.required"
                >Please enter recipient mobile</span
              >
            </div>
            <div class="position-relative">
              <div class="form-group mt20">
                <label>Street Name</label>
                <!-- <small class="color-lightgrey" style="display: block"
                  >Unit number, street name</small -->

                <input
                  type="text"
                  formControlName="address"
                  class="form-control"
                  placeholder="Enter your street name"
                  [ngClass]="{
                    'is-invalid':
                      submittedAddressForm && addressFormFormVal.address.errors
                  }"
                />
              </div>
            </div>

            <div
              *ngIf="submittedAddressForm && addressFormFormVal.address.errors"
              class="error"
            >
              <span *ngIf="addressFormFormVal.address.errors.required"
                >Please enter street name</span
              >
            </div>

            <div class="position-relative">
              <div class="form-group mt20">
                <label>Unit Number</label>
                <!-- <small class="color-lightgrey" style="display: block"
                  >Unit number, street name</small -->

                <input
                  type="text"
                  formControlName="unitnumber"
                  class="form-control"
                  placeholder="Enter your unitnumber"
                  [ngClass]="{
                    'is-invalid':
                      submittedAddressForm &&
                      addressFormFormVal.unitnumber.errors
                  }"
                />
              </div>
            </div>

            <div
              *ngIf="
                submittedAddressForm && addressFormFormVal.unitnumber.errors
              "
              class="error"
            >
              <span *ngIf="addressFormFormVal.unitnumber.errors.required"
                >Please enter address</span
              >
            </div>

            <div class="position-relative">
              <div class="form-group mt20">
                <label>Postal code</label>
                <input
                  type="number"
                  formControlName="postalCode"
                  class="form-control"
                  (change)="postalCode($event)"
                  placeholder="Enter postal code"
                  [ngClass]="{
                    'is-invalid':
                      (submittedAddressForm &&
                        addressFormFormVal.postalCode.errors) ||
                      invalidPostal
                  }"
                />
              </div>
            </div>

            <div
              *ngIf="
                submittedAddressForm && addressFormFormVal.postalCode.errors
              "
              class="error"
            >
              <span *ngIf="addressFormFormVal.postalCode.errors.required"
                >Please enter postal code</span
              >
            </div>
            <div class="error" *ngIf="invalidPostal">
              <span>Unable to deliver this location</span>
            </div>
            <!-- <div class="checkbox">
              <input
                type="checkbox"
                class="orm-check-input mr-2 position-relative ml-0"
                value=""
                formControlName="saveDefaultAddress"
              />
              <label>Save as default address </label>
            </div>

            <div
              *ngIf="
                submittedAddressForm &&
                addressFormFormVal.saveDefaultAddress.errors
              "
              class="error"
            >
              <span
                *ngIf="addressFormFormVal.saveDefaultAddress.errors.required"
                >Please check the checkbox</span
              >
            </div> -->

            <div class="text-center mt50 mb20">
              <button class="primaryBtn Btn hl50" type="submit">
                Confirm redemption
                <img
                  src="../../assets/images/arrow-right-white.png"
                  class="v-sub mx-2"
                />
              </button>
            </div>
          </form>
          <p class="" *ngIf="enufPoints" class="center" style="color: red">
            Fail to issue reward as current points are less than points required
            for redemption
          </p>
          <p class="" *ngIf="couponExpired" class="center" style="color: red">
            Fail to issue reward as coupon expired
          </p>
        </section>
      </div>
      <div id="test-l-3" class="content text-center pb70">
        <div class="mt-4 step-3">
          <div class="text-center px-3">
            <img src="../../assets/images/points-added.png" />
            <h2 class="fs32 font-darkblue">Rewards confirmed</h2>
            <p class="fs20">
              Your remaining points:
              <span class="font-blue">{{ loyaltyPoints }}</span>
            </p>
            <p class="mt-4">
              Your delivery will be scheduled within the next 2 to 3 weeks. You
              will receive a notification on the day of delivery.
            </p>
          </div>

          <section class="mb-5 rewards-checkout step-1 mt-5 text-left pb70">
            <h4 class="fs20 mb-4">Redemption summary</h4>
            <div class="product-card m-1">
              <span class="font-blue cursor-pointer">{{ count }} x</span>
              <img [src]="rewardInfo.image" class="image" alt="product" />
              <div class="product-name">
                <h4 class="fs16 font-darkblue">
                  {{ rewardInfo.name }}
                </h4>
              </div>
              <div class="product-points">
                <h4>{{ rewardInfo.intouchPoints }} Pts</h4>
              </div>
            </div>
            <div class="points-used my-4 text-right">
              <p class="mb-2">Total points to be used</p>
              <h4 class="fs24 font-darkblue">
                {{ count * rewardInfo.intouchPoints }} pts
              </h4>
            </div>
            <button
              type="submit"
              class="primaryBtn my-3 fW500 Btn"
              (click)="backToDashboardBtn()"
            >
              Back to dashboard
            </button>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
