import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-rewards-brand-selection',
  templateUrl: './rewards-brand-selection.component.html',
  styleUrls: ['./rewards-brand-selection.component.scss'],
})
export class RewardsBrandSelectionComponent implements OnInit {
  productName: any;
  productList = [];
  rewardsList: any;
  count = 1;
  disabled = false;
  customerData: any;
  loyaltyPoints: any;
  customerNamePresent: any = false;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private bugsnagService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.disabled = this.count <= 1 ? true : false;
    this.getCustomer();
    this.productName = this.route.snapshot.params.name;
    this.getRewardsList();
  }

  getRewardsList() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          this.rewardsList = res['rewardList'];
          console.log('rewardsList: ', this.rewardsList);
          if (this.productName == 'ensure') {
            this.productName = 'Ensure';
            this.ensureProductList();
          } else if (this.productName == 'pediasure') {
            this.productName = 'PediaSure';
            this.pediasureProductList();
          } else if (this.productName == 'glucerna') {
            this.productName = 'Glucerna';
            this.glucernaProductList();
          } else if (this.productName == 'similac') {
            this.productName = 'Similac';
            this.similacProductList();
          }
        }
      }, err => {
        console.log(err);
        this.spinner.hide()
        this.bugsnagService.notifyError(err);
      });
  }

  ensureProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'ensure') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "ensure"
        this.productList.push(reward);
      }
    });
  }

  pediasureProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'pediasure') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "pediasure"

        this.productList.push(reward);
      }
    });
  }

  similacProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'similac') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "similac"

        this.productList.push(reward);
      }
    });
  }

  glucernaProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'glucerna') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "glucerna"

        this.productList.push(reward);
      }
    });
  }

  onIncrement(index) {
    this.productList[index].quantity += 1
    this.productList[index].disabled = this.productList[index].quantity <= 1 ? true : false;
  }

  onDecrement(index) {
    this.productList[index].quantity -= 1
    this.productList[index].disabled = this.productList[index].quantity <= 1 ? true : false;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.spinner.hide()
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          this.loyaltyPoints = this.customerData.loyalty_points;
          this.customerNamePresent = true;
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide()
        this.bugsnagService.notifyError(err);
      }
    );
  }

  onClickGetReward(reward) {

    this.router.navigate([
      '/rewards/checkout',
      {
        name: reward.name,
        intouchPoints: reward.intouchPoints,
        image: reward.imageUrl,
        quantity: reward.quantity,
        id: reward.id,
        category: reward.category,
        label: reward.label,
        tier: reward.tier,
      },
    ]);
  }
}
