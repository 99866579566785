<app-header title="Change Mobile Number"></app-header>
<section class="globalContainer px15 py-5">
    <div class="editAccount px15">
        <div class="formContainer edit-account-form">
            <div class="fieldset">
                <div class="field">
                    <label for="mobile">Mobile Number</label>
                    <input name="mobile" type="tel" placeholder="9100 1234 1234" />
                </div>
                <div class="field">
                    <label for="password">Password</label>
                    <p>To change mobile number, you must enter your password</p>
                    <input name="password" type="password" />
                </div>
                <div class="field mt50">
                    <button type="submit" class="primaryBtn Btn">Continue</button>
                </div>
            </div>
        </div>
    </div>
</section>