<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>Muscle Health</h1>
  </div>
</div>
<section class="globalContainer">
  <section class="muscle-health min-vh-100 bg">
    <div class="main-banner">
      <img
        class="width-100"
        src="../../../../assets/images/ensure/muscle_health_main_banner.png"
      />
      <p>How to build and preserve your muscle strength</p>
    </div>
    <div class="text-content-dark pt-5">
      <p>
        Adults between the ages of 40 and 65 years old should be on “high alert”
        and take active steps to strengthen your muscle and body for overall
        health.
      </p>
      <h3 class="mt-5">Why do you need to keep your muscles strong?</h3>
      <p>
        As we age, we lose muscle mass. When losing muscle mass we face higher
        risk of:
      </p>

      <div class="d-flex higher-risk mt-3">
        <div class="text-center">
          <img src="../../../../assets/images/ensure/muscle_immobility.png" />
          <label class="mt-2">IMMOBILITY</label>
        </div>
        <div class="text-center">
          <img
            src="../../../../assets/images/ensure/muscle_increased_falls.png"
          />

          <label class="mt-2">INCREASED FALLS</label>
        </div>
        <div class="text-center">
          <img
            src="../../../../assets/images/ensure/muscle_physical_and_mental_fatigue.png"
          />
          <label class="mt-2">PHYSICAL AND MENTAL FATIGUE</label>
        </div>
        <div class="text-center">
          <img
            src="../../../../assets/images/ensure/muscle_loss_of_independence.png"
          />

          <label class="mt-2">LOSS OF INDEPENDENCE</label>
        </div>
      </div>

      <h3 class="mt-3">Your muscle loss starts earlier than you think!</h3>
      <p>
        After 40, we start to lose muscle mass and that can be as much as 33% of
        muscle mass loss between 40 - 80 years old.*
      </p>
    </div>
    <div class="text-content-light pt-3">
      <span><sup>*</sup></span> Baier S et al,JPEN.2009;33(1):71-82.

      <img
        class="width-100 mt-2"
        src="../../../assets/images/ensure/Muscle-Loss-for-age-40-60-80_tcm150-142841.gif"
      />
    </div>
    <div class="text-content-dark pt-5">
      <h3>Do regular muscle self-screening</h3>
      <p>
        Since muscle mass is an indicator of health, self-screening methods can
        evaluate your muscle strength and muscle health.
      </p>
    </div>
    <img
      class="width-100 mt-2"
      src="../../../../assets/images/ensure/whos_the_real_uncle_or_aunties.png"
    />

    <div class="box-div">
      <div class="product-description">
        <div class="text-content-dark p-0">
          <h4>UNCOVER YOUR MUSCLE AGE NOW AT</h4>
          <img
            src="../../../../assets/images/ensure/stand_for_health.png"
            (click)="takeATestNowBtn()"
            class="s4s-image"
          />
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block btn_custom mt-3 mb-2"
            (click)="takeATestNowBtn()"
          >
            Take the test now
            <img
              class="btn_arrow_icon"
              src="../../../../assets/images/ensure/btn_arrow_icon.svg"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="bottom-div">
      <div class="text-content-dark mt-5">
        <h3>Build strength with daily exercises!*</h3>

        <div class="d-flex muscle-diff mt-3">
          <div>
            <img src="../../../../assets/images/ensure/one_leg_stands.png" />
            <p class="mt-2">
              For improving your balance, do one minute for each leg.
            </p>
          </div>
          <div>
            <img
              src="../../../../assets/images/ensure/squats_daily_exercise.png"
            />

            <p class="mt-2">
              For strengthening your leg muscles, repeat 5-6 times in sync with
              deep breathing.
            </p>
          </div>
        </div>
      </div>
      <div class="text-content-light pt-3">
        <span><sup>*</sup></span> Nakamura K, Ogata T. Locomotive Syndrome:
        Definition and Management. Clin Rev Bone Miner Metab. 2016; 14(2):56-57.
      </div>

      <div class="text-content-dark mt-5">
        <h3>You can build strength with a protein rich diet!</h3>
        <p class="mt-2">Fill your plate with protein, calcium and vitamin D.</p>
      </div>

      <div class="box-div">
        <div class="product-description mt-2">
          <div class="text-content-dark p-0 text-center">
            <img src="../../../../assets/images/ensure/who_logo.png" />
            <p class="mt-3 mb-3">
              World Health Organization recommends a complete, balanced Oral
              Nutrition Supplement<sup>1</sup> (ONS) to improve muscle mass and
              strength which supports mobility and vitality.<sup>2</sup>
            </p>

            <div class="text-content-light p-0 text-left">
              <span class="text-decoration-underline"><sup>1</sup></span> A
              complete, balanced diet must contain sufficient amount of
              carbohydrate, protein, fat, vitamins, minerals and fibre in the
              required amounts and in the right proportion. <br /><br />
              <span class="text-decoration-underline"><sup>2</sup></span>
              Integrated care for older people: guidelines on community-level
              interventions to manage declines in intrinsic capacity. Geneva:
              World Health Organization; 2017. License: CC BY-NC-SA 3.0 IGO.
            </div>
          </div>
        </div>
      </div>
      <div class="text-content-dark mt-3 mb-5">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block btn_custom mt-3 mb-2"
          (click)="LearnAboutMuscleHealthBtn()"
        >
          Learn more about muscle health
          <img
            class="btn_arrow_icon"
            src="../../../../assets/images/ensure/btn_arrow_icon.svg"
          />
        </button>
      </div>

      <app-scroll-to-top></app-scroll-to-top>
    </div>
  </section>
  <div class="bottom-bg"></div>
</section>

<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
