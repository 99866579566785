<div class="container">
    <div class="logo"></div>
    <div class="name">Simple Sample</div>
    <div id="description" class="description" *ngIf="showDescription">
      Start the scanner and then aim at a barcode to get the encoded data.
    </div>
  
    <div id="lib-loading" *ngIf="!scannerReady">
      <br />Library is loading, please wait...
    </div>
  
    <button id="barcode-picker-starter-button" *ngIf="showButton" (click)="startBarcodePicker()">
      Start Scanning
    </button>
  
    <div id="barcode-result" class="result-text">Scanned Code <strong>{{ result }}</strong></div>
  
    <scandit-barcode-picker
      #barcodePicker
      id="barcode-picker"
      configure.licenseKey="AZeBog5ZLHLHIofY1xfs5Xg7yF3mLmYttkOJdAE4a/L7OUAn6H1a/MYwBasWGHBGonso48ppj2k0SKO00lvvjYAFVcsRUvvIvUPWcKhJVtnSb3vceUBO6/A2xyKrC/TLSGoiuENx6lSQWl3qsCMXTV5P1rT4JeehDVTLH8xIJVT1cgtQoyGD9VchR6xuQ5+DUS+xhAj9yLKX/alhgbnbTwUunGvg6t2TOgTDfe/kWgAZ7MmccInnjC8ieATE60FyHP24nKK5rgw1UVAY5eKwpPq7QeVvjrcPm2+qehvKhaCwuUaSs1DWilxoOx1I8DTrwd3kXt/ajscauOkRWF/5tjvRgqnQbTPz2HIgcW9ESMZPbqLoOfxHy8Gd3sgmDPlo14WVKSJ3KUCmw8WTqSIv18n8G8Noxl1dq4RIhKLCYgX27jOGk6cQdS/QRyEaQrOEokSuig2FgWyAR2dWrCZNyNDQIaLZfGtID0djpFuIono5DaWWLbOP5e2obEqxPtH/JUidyt3qVd3JDXwEPncwPu+cbqDI9M1ZpLWcKgDCX05qTOp63ABg+/flx2XkoijL4tQnhta99u7lEKDMEykQqWfOOvxlWa7i8u3TNTG7ZYmD912v9TtmgQJYlUgUqpGmHzA5rrJKToQoO0dUWdOAcNzVXQG2M7OEp2lHRNXQ7ykQaujrRL3NKGAMidkQZiLhmEP1MkkoavOHB0Z3lRM8RCsEK8SnA/PAJ4TV35BnjHa4qzH3JKBw3agyIIMVIBBeDyRiCvy8/96ZsbMtR7gs/+pAq+MynUKGFp95oiEvRo9YAorjJhPGQTDi/eCXAqJOGS6azcq8sY8ulV+za4CgDJG5UynHWAKY5wu88vLVwe8jgbvf"
      configure.engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/"
      scanningPaused="true"
      visible="false"
      playSoundOnScan="true"
      vibrateOnScan="true"
      scanSettings.enabledSymbologies='["QR"]'
      (ready)="onReady()"
      (scan)="onScan($event)">
    </scandit-barcode-picker>
  </div>
  