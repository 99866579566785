import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild('notLoginOrSignup', { static: true })
  notLoginOrSignup: ModalDirective;
  @ViewChild('button') button!: ElementRef;

  @Input() customerNamePresent: any;
  url: string;
  brand: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.url.subscribe((url) => {
      this.url = url[0].path;
      console.log(this.url);
    });
    this.brand = sessionStorage.getItem('categoryCode');
    console.log('brand from menu', this.brand);
    if (this.brand.startsWith('ensure')) {
      this.brand = 'ensure';
    } else if (this.brand.startsWith('pediasure')) {
      this.brand = 'pediasure';
    } else {
      //default
      this.brand = 'ensure';
    }
    console.log('/////////..........', this.brand);
  }

  onClickProfile() {
    if (this.customerNamePresent) {
      this.router.navigate(['profile']);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickScan() {
    if (this.customerNamePresent) {
      this.router.navigate(['qr-scanner']);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickRewards() {
    if (this.customerNamePresent) {
      this.router.navigate(['rewards']);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickCancel() {
    this.notLoginOrSignup.hide();
  }
}
