import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toast } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { Location } from '@angular/common';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
})
export class TransactionHistoryComponent implements OnInit, AfterViewInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent: any = false;
  pointsList: any;
  totalLength: any;
  productList = [];
  checkedStatusListVochers = [];
  tempArray = [];
  tempArrayVochers = [];
  vochers = [];
  filteredVochersList = [];
  newArray = [];
  newArrayVochers = [];
  rewardDetail: any;
  voucherList: any;
  REFERENCE;
  TODAY;
  YESTERDAY;
  filteredArray = [];
  checkedStatusList = [];
  filteredProductList = [];
  @ViewChild('button', { static: true }) button: ElementRef;
  tempArrayByStatus = [];
  checkedDateFilterList = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private location: Location,
    private bugsnagService: BugsnagService
  ) { }

  ngAfterViewInit(): void {
    console.log(this.button);
  }

  ngOnInit(): void {
    this.REFERENCE = moment();
    this.TODAY = this.REFERENCE.clone().startOf('day');
    this.YESTERDAY = this.REFERENCE.clone().subtract(1, 'days').startOf('day');

    this.getCustomer();
    this.getAllPoints();
    // this.getRewardList();
    this.getRedemtionDetailFinal();
  }
  onClickBack() {
    this.location.back();
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;

          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == 'block_count';
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(['/block-notice']);
          }
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      }
    );
  }

  getAllPoints() {
    let mobile = sessionStorage.getItem('mobileNo');
    this.spinner.show();
    this.apiService
      .getProductsList(mobile)
      .pipe(first())
      .subscribe((res) => {
        this.spinner.hide();
        this.pointsList = res['result'];
        this.totalLength = this.pointsList.length;
        // console.log("points: ", this.pointsList)
        // this.pointsList.push({
        //   id: 100,
        //   product_name: 'Mamatha',
        //   product_image: null,
        //   receipt_status: 'awaiting',
        //   productCode: 1000,
        //   submission_date: moment("2021-02-04T07:13:09.000Z").format(),
        //   points: 1000,
        //   status: 'awaiting',
        // })
        this.pointsList.forEach((product: any) => {
          let productItem = {
            id: product?.id,
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format(),
            points: product.points,
            productStatus: product.status,
            color: '',
            30: '',
            90: '',
            yearAgo: '',
          };

          // productItem.submissionDate = "06-01-2022"
          productItem['30'] = this.isThirtyDaysAgo(productItem.submissionDate);
          productItem['yearAgo'] = this.isYearAgo(productItem.submissionDate);
          productItem['90'] = this.isNinetyDaysAgo(productItem.submissionDate);

          if (this.isToday(productItem.submissionDate)) {
            productItem.submissionDate = 'Today';
          } else if (this.isYesterday(productItem.submissionDate)) {
            productItem.submissionDate = 'Yesterday';
          } else {
            productItem.submissionDate = moment(
              productItem.submissionDate
            ).format('DD/MM/YYYY');
          }
          // productItem.status = 'awaiting'

          if (productItem.status == 'approved') {
            productItem.status = 'SUCCESS';
            productItem.color = '#30C878';
            if (productItem.points == null) {
              productItem.points = '';
            } else {
              productItem.points = '+' + productItem.points + ' ' + 'pts';
            }
          } else if (productItem.status == 'pending') {
            productItem.status = 'PENDING RECEIPT REQUEST';
            productItem.points = '';
            productItem.color = '#8b8f91';
          } else if (productItem.status == 'rejected') {
            productItem.status = 'INVALID';
            productItem.points = '';

            productItem.color = '#fe2121';
          } else if (productItem.status == 'awaiting') {
            productItem.status = 'PENDING POINTS CONFIRMATION';
            productItem.color = '#EFBE35';
            productItem.points = '';
          }

          this.productList.push(productItem);
        });
        this.productList = this.productList.filter((v, i, a) => a.findIndex(t => (t.productCode === v.productCode)) === i)

        this.filteredProductList = this.productList.reverse();
        console.log("filter: ", this.filteredProductList)
      }),
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      };
  }

  isToday(momentDate) {
    let today = moment(this.TODAY).format('');
    return moment(momentDate).isSame(today, 'd');
  }
  isYesterday(momentDate) {
    let yesterday = moment(this.YESTERDAY).format('');
    return moment(momentDate).isSame(yesterday, 'd');
  }

  isSixtyDaysAgo(submissionDate) {
    var today = moment().format();
    var sixtyDaysBefore = moment().subtract(60, 'days').format();
    return moment(submissionDate).isBetween(
      moment(sixtyDaysBefore),
      moment(today)
    )
      ? '60Days'
      : undefined;
  }

  isYearAgo(submissionDate) {
    var today = moment().format();
    var yearAgo = moment().subtract(1, 'year').format();
    return moment(submissionDate).isBetween(moment(yearAgo), moment(today))
      ? undefined
      : 'yearAgo';
  }

  isNinetyDaysAgo(submissionDate) {
    var today = moment().format();
    var ninetyDaysBefore = moment().subtract(90, 'days').format();
    return moment(submissionDate).isBetween(
      moment(ninetyDaysBefore),
      moment(today)
    )
      ? '90Days'
      : undefined;
  }

  isThirtyDaysAgo(submissionDate) {
    var today = moment().format();
    var thirtyDaysBefore = moment().subtract(30, 'days').format();
    return moment(submissionDate).isBetween(
      moment(thirtyDaysBefore),
      moment(today)
    )
      ? '30Days'
      : undefined;
  }

  onApplyFilters() {
    this.tempArray = [];
    this.tempArrayByStatus = []
    this.newArray = this.productList;
    console.log(this.checkedStatusList);

    if (this.checkedStatusList.length > 0) {
      this.checkedStatusList.forEach((value) => {
        this.newArray.forEach((product) => {
          if (product.status == value) {
            this.tempArray.push(product);
            if (this.checkedDateFilterList.length > 0) {
              this.checkedDateFilterList.forEach((value) => {
                if (product[30] == value || product['yearAgo'] == value ||
                  product[90] == value) {
                  this.tempArray.push(product)
                } else {
                  this.tempArray.pop()
                }
              })
            }
          }
        });
      });
    } else {
      this.checkedDateFilterList.forEach((val) => {
        this.newArray.forEach((product) => {
          if (product[30] == val || product['yearAgo'] == val ||
            product[90] == val) {
            this.tempArray.push(product)
          }
        })
      })
    }


    this.filteredProductList = this.tempArray
    console.log("fil: ", this.filteredProductList)
    if (this.checkedStatusList.length == 0 && this.checkedDateFilterList.length == 0) {
      this.filteredProductList = this.productList
    }
    this.filteredProductList = this.filteredProductList.filter(
      (v, i, a) => a.findIndex((t) => t.productCode === v.productCode) === i
    );
  }

  changeSelection(event) {
    if (event.target.checked) {
      if (event.target.value == "30Days" || event.target.value == "90Days" || event.target.value == "yearAgo") {
        this.checkedDateFilterList.push(event.target.value)
      } else {
        this.checkedStatusList.push(event.target.value);
      }
    } else {
      if (event.target.value == "30Days" || event.target.value == "90Days" || event.target.value == "yearAgo") {
        this.checkedDateFilterList = this.checkedDateFilterList.filter(
          (e) => e != event.target.value
        )
      } else {
        this.checkedStatusList = this.checkedStatusList.filter(
          (e) => e != event.target.value
        );
      }

    }
  }

  onClickExitFilter() {
    console.log(this.checkedStatusList);
    var inputs = document.querySelectorAll('.check3');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i]['checked'] = false;
    }
    this.checkedStatusList = [];
    this.checkedDateFilterList = []
    this.filteredProductList = this.productList
  }

  onClickExitFilterVocher() {
    console.log(this.checkedStatusListVochers);
    var inputs = document.querySelectorAll('.check4');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i]['checked'] = false;
    }
    this.checkedStatusListVochers = [];
    this.filteredVochersList = this.vochers
  }

  getRedemtionDetailFinal() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService.getMarvalVouchers(mobile, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          this.pointsList = res?.response?.customer?.redemptions?.points?.point;
          this.voucherList = res?.userVouchers;
          this.voucherList = this.voucherList.filter(
            (ress) => ress.rewardDetails != null
          );
          let voucherFilter = this.voucherList.forEach((voucherList) => {
            let tierData = voucherList.rewardDetails.tier;
            //let tierData = 'ABC123';
            let pointFilter = this.pointsList.filter((point) => {
              return point.notes === tierData;
            });
            let rewardFilter = null;
            if (this.rewardDetail != null || this.rewardDetail != undefined) {
              rewardFilter = this.rewardDetail.filter((reward) => {
                return reward.tier === tierData;
              });
            }
            let foundReward = null;
            if (rewardFilter != null && rewardFilter.length > 0) {
              foundReward = rewardFilter[0];
            }
            let tottal_points = 0;
            let quantity = 0;
            pointFilter.forEach((pointFilter) => {
              tottal_points = tottal_points + pointFilter.points_redeemed;
              if (foundReward != null) {
                quantity =
                  quantity +
                  pointFilter.points_redeemed / foundReward.intouchPoints;
              } else {
                quantity = voucherList.vouchers.length;
              }
            });
            let transDate = voucherList.vouchers[0].issued;

            voucherList['total_points'] = tottal_points;
            voucherList['quantity'] = quantity;
            //voucherList['displayDate'] = moment(transDate).add(2.5, 'hours').format('DD MMM yyyy');
            voucherList['displayDate'] = transDate;
          });
          setTimeout(() => {
            this.voucherList.sort((a, b) =>
              moment(a.issuedDate, 'DD-MM-YYYY').isBefore(
                moment(b.issuedDate, 'DD-MM-YYYY')
              )
                ? 1
                : -1
            );
            this.spinner.hide();
          }, 1000);

          this.voucherList.forEach((vocher) => {
            let obj = {
              name: vocher['rewardDetails'].name,
              image: vocher['rewardDetails'].imageUrl,
              points: vocher.total_points,
              date: moment(vocher.displayDate).format(),
              id: vocher['rewardDetails'].rewardId,
              30: '',
              90: '',
              yearAgo: '',
            };
            obj[30] = this.isThirtyDaysAgo(obj.date);
            obj[90] = this.isNinetyDaysAgo(obj.date);
            obj['yearAgo'] = this.isYearAgo(obj.date);

            if (this.isToday(obj.date)) {
              obj.date = 'Today';
            } else if (this.isYesterday(obj.date)) {
              obj.date = 'Yesterday';
            } else {
              obj.date = moment(obj.date).format('DD/MM/YYYY');
            }

            this.vochers.push(obj);
            // this.vochers.push(
            //   {
            //     name: 'super-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 22,
            //     30: '30Days',
            //   },
            //   {
            //     name: 'spider-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 23,
            //     90: '90Days',

            //   },
            //   {
            //     name: 'hang-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 25,
            //     year: 'yearAgo'

            //   }

            // )
          });

          this.filteredVochersList = this.vochers;
          console.log('filteredVochers: ', this.filteredVochersList);

          // this.voucherList = this.voucherList.push(this.pointsList );
          // this.pointsList.forEach((element:any) => {
          //   this.pointData = element?.points_redeemed
          //   console.log( this.pointData, 'this.pointData')
          // });
        }
      },
      (err) => {
        console.log(err, 'err--');
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      }
    );
  }

  changeSelectionVochers(event) {
    if (event.target.checked) {
      this.checkedStatusListVochers.push(event.target.value);
    } else {
      this.checkedStatusListVochers = this.checkedStatusListVochers.filter(
        (e) => e != event.target.value
      );
    }
  }

  onApplyVochers() {
    this.tempArrayVochers = [];
    this.newArrayVochers = this.vochers;
    console.log(this.checkedStatusListVochers);
    this.checkedStatusListVochers.forEach((value) => {
      this.newArrayVochers.forEach((vocher) => {
        if (
          vocher[30] == value ||
          vocher['yearAgo'] == value ||
          vocher[90] == value
        ) {
          this.tempArrayVochers.push(vocher);
        }
      });
    });

    this.filteredVochersList = this.tempArrayVochers;
    if (this.checkedStatusListVochers.length == 0) {
      this.filteredVochersList = this.vochers
    }
    this.filteredVochersList = this.filteredVochersList.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
  }

  onclickUploadReceipt(id) {
    let data = {
      data: id,
    };
    this.router.navigate(['/scan/camera', data]);
  }
}
