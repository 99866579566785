import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  signupForm: FormGroup;
  submittedSignUpForm: boolean = false;
  showPasswordText: boolean = false;
  showEyeCloseIcon: boolean = true;
  showEyeOpenIcon: boolean = false;
  isParamMobileNumber: any = false;
  paramMobileNumber: any;
  brandsList: any = [
    { id: 'Pediasure', name: 'Pediasure' },
    { id: 'Ensure', name: 'Ensure' },
    { id: 'Similac', name: 'Similac' },
    { id: 'Glucerna', name: 'Glucerna' },
  ];
  @ViewChild('tncModal', { static: true }) tncModal: ModalDirective;
  @ViewChild('privacyPolicy', { static: true }) privacyPolicy: ModalDirective;
  showconfirmPasswordText: boolean = false;
  showConfirmEyeCloseIcon: boolean = true;
  signUpFormData: any;
  regMobnum: any;
  showConfirmPasswordtext: boolean = false;
  showEyeCloseIconConfirm: boolean = true;
  showEyeOpenIconConfirm: boolean = false;
  emailExsistError: boolean;
  public barLabel: string = "Password strength:";
  public strengthLabels = ['', 'WEAK', 'NORMAL', 'STRONG', 'GREAT!'];
  public thresholds = [90, 75, 45, 25];
  strength: number;
  passwordNotMaching: boolean = false;
  isPasswordPatternCorrect: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private bugsnagService: BugsnagService
  ) { }

  strengthChanged(strength: number) {
    this.strength = strength;
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group(
      {
        mobileNumber: [
          '',
          [
            Validators.required,
            Validators.pattern(`^((\\+65-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
            // Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$'),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
            //Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$'),
          ],
        ],
        fullName: ['', Validators.required],
        email: ['', Validators.email],
        interestedInAbbottBrands: [''],
        abbottBrands: this.formBuilder.array([]),
        termsConditions: [false, Validators.requiredTrue],
        privacyPolicy: [false, Validators.requiredTrue],
        contactByEmailOrSMS: [],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    this.paramMobileNumber = this.route.snapshot.params.mobileNumber;
    console.log('mobile number from route params', this.paramMobileNumber);
    if (this.paramMobileNumber != null) {
      this.isParamMobileNumber = true;
      this.signupForm.patchValue({
        mobileNumber: this.paramMobileNumber,
      });
    }
  }


  loginSignupnumber() {
    this.router.navigate([
      'login-signup',
      { mobileNumber: this.paramMobileNumber },
    ]);
  }
  onPasswordConfirmChanged($event) {
    let password = this.signupForm.controls.password.value
    if ($event != password && password.length > 0) this.passwordNotMaching = true
    else this.passwordNotMaching = false
  }

  onPasswordCheckPattern($event) {
    this.isPasswordPatternCorrect = this.signupForm.controls.password.status == 'INVALID' ? true : false
  }

  onCheckboxChange(e) {
    const abbottBrands: FormArray = this.signupForm.get(
      'abbottBrands'
    ) as FormArray;
    if (e.target.checked) {
      abbottBrands.push(new FormControl(e.target.value));
    } else {
      const index = abbottBrands.controls.findIndex(
        (x) => x.value === e.target.value
      );
      abbottBrands.removeAt(index);
    }
  }

  onClickEyeCloseIcon() {
    this.showEyeCloseIcon = false;
    this.showPasswordText = true;
    this.showEyeOpenIcon = true;
  }

  onClickEyeOpenIcon() {
    this.showPasswordText = false;
    this.showEyeCloseIcon = true;
    this.showEyeOpenIcon = false;
  }

  onClickShowConfirmPassword() {
    this.showConfirmPasswordtext = !this.showConfirmPasswordtext;
    this.showEyeCloseIconConfirm = !this.showEyeCloseIconConfirm;
    this.showEyeOpenIconConfirm = !this.showEyeOpenIconConfirm;
  }

  get signUpFormVal() {
    return this.signupForm.controls;
  }

  onPnpClose() {
    this.privacyPolicy.hide();
  }

  onClickPrivacyPolicy() {
    this.privacyPolicy.show();
  }

  onMobNumber() {
    console.log(this.signUpFormVal.mobileNumber.errors);
    // console.log(this.paramMobileNumber);
  }

  onStrengthChange(score) {
    console.log('new score', score);
  }

  onSubmitSignUpForm() {
    this.submittedSignUpForm = true;
    if (this.signupForm.invalid) {
      return;
    } else {
      console.log('signupform: ', this.signupForm.value);
      this.signUpFormData = this.signupForm.value;
      this.regMobnum = this.signUpFormData.mobileNumber;
      sessionStorage.setItem('mobileNo', this.regMobnum);

      let formData = {
        identifierType: 'MOBILE',
        identifierValue: String(this.regMobnum),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: this.signUpFormData.password,
        confirmPassword: this.signUpFormData.confirmPassword,
      };

      this.spinner.show();
      this.apiService
      // .checkExistingEmail(encodeURIComponent(this.signUpFormData.email))
      // .pipe(first())
      // .subscribe(
      //   (res: any) => {
      //if (res.result.statusCode == 200) {
      // if (res.result.message === 'User not found') {
      this.apiService
        .tokenGenerate(formData)
        .pipe(first())
        .subscribe(
          (res) => {
            if (res['status'].code == 200) {
              this.spinner.hide();
              let sessionId = res['user'].sessionId;
              sessionStorage.setItem(
                'sessionID',
                JSON.stringify(sessionId)
              );

              let otpGenerateData = {
                identifierType: 'MOBILE',
                identifierValue: String(this.regMobnum),
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: sessionId,
              };
              this.spinner.show();
              this.apiService
                .otpGenerate(otpGenerateData)
                .pipe(first())
                .subscribe(
                  (res: any) => {
                    this.spinner.hide();
                    let formValue = this.signUpFormData;
                    console.log(formValue)
                    formValue['reset'] = false;
                    const navigationExtras: NavigationExtras = {
                      state: { formValue: formValue },
                    };
                    this.router.navigate(['otp'], navigationExtras);
                    if (res['status'].code == 200) {
                      
                    } else {
                      console.log(res['status'].message);
                    }
                  },
                  (err) => {
                    this.spinner.hide();
                    console.log(
                      err,
                      '=== OTP gererate error list ======='
                    );
                    this.bugsnagService.notifyError(err, otpGenerateData);
                  }
                );
            }
          },
          (err) => {
            this.spinner.hide();
            console.log(err, '=== token generate err=======');
            this.bugsnagService.notifyError(err, formData);
          }
        );
      // }
      // }
      //},
      //   (err) => {
      //     this.emailExsistError = true; //email arleady exsist
      //     console.log('emailExsistError: ', this.emailExsistError);
      //     console.log('email already exsist---');
      //     alert('email already exsist---');
      //     // alert('Email already existing');
      //     this.spinner.hide();
      //   }
      // );
    }
    
  }

  onClickTNC() {
    this.tncModal.show();
  }

  onClickClose() {
    this.tncModal.hide();
  }

  trimEmail(event){
    event.target.value = event.target.value.trim();
    this.signupForm.patchValue({
      email: event.target.value, 
    });
  }
}
