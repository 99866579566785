<section class="globalContainer transactionContainer pb70">
  <div class="dasboard-profile-card pb-4 bbl20 bbr20 rewards-profileBg">
    <div class="transaction-backBtn" (click)="onClickBack()">
      <span>
        <i class="fa fa-angle-left"></i>
      </span>
      Back
    </div>
    <div class="row mx-0 px20">
      <div class="col-6 point-text p-0">
        <p class="fs20 fW600 mt-3">Transaction History</p>
      </div>
      <div class="col-6 text-right p-0">
        <p class="point-text">Total Reward Points</p>
        <p class="points">{{ loyaltyPoints }}</p>
      </div>
    </div>
  </div>
  <ul class="nav nav-pills bbl20 bbr20" id="transaction-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        data-bs-toggle="pill"
        data-bs-target="#points-earned"
        type="button"
        role="tab"
        aria-controls="points-earned"
        aria-selected="false"
      >
        Points Earned
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link btn-block"
        data-bs-toggle="pill"
        data-bs-target="#points-used"
        type="button"
        role="tab"
        aria-controls="points-used"
        aria-selected="false"
      >
        Points Used
      </button>
    </li>
  </ul>
  <div class="tab-content px30 my-4 mb-5 pb70" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="points-earned"
      role="tabpanel"
      aria-labelledby="points-earned"
    >
      <div class="dropdown d-flex justify-content-end mb-3">
        <a
          #button
          class="font-blue text-no-underline"
          href="#"
          role="button"
          id="dropdownMenuLink-1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img class="v-unset" src="../../../../assets/images/filter.png" />
          FILTER
        </a>

        <div
          class="dropdown-menu w-100 border-0"
          aria-labelledby="dropdownMenuLink-1"
        >
          <span
            class="exit"
            *ngIf="
              checkedStatusList?.length > 0 || checkedDateFilterList?.length > 0
            "
            (click)="onClickExitFilter()"
            ><img
              class="mr3"
              src="../../../../assets/images/Group 5085.svg"
            />EXIT FILTER</span
          >
          <div class="filterBySection">
            <p>Filter by Status</p>
            <ul>
              <li>
                Success
                <span>
                  <input
                    value="SUCCESS"
                    class="check3"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-status"
                    for="filter-status"
                  />
                </span>
              </li>
              <li>
                Pending Points Confirmation
                <span>
                  <input
                    class="check3"
                    value="PENDING POINTS CONFIRMATION"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-status"
                    for="filter-status"
                  />
                </span>
              </li>
              <li>
                Pending Receipt Request
                <span>
                  <input
                    class="check3"
                    value="PENDING RECEIPT REQUEST"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-status"
                    for="filter-status"
                  />
                </span>
              </li>
              <li>
                Invalid
                <span>
                  <input
                    class="check3"
                    value="INVALID"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-status"
                    for="filter-status"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div class="filterBySection">
            <p>Filter by date</p>
            <ul>
              <li>
                Last 30 days
                <span>
                  <input
                    class="check3"
                    value="30Days"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
              <li>
                Last 3 months
                <span>
                  <input
                    class="check3"
                    value="90Days"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
              <li>
                Last year
                <span>
                  <input
                    class="check3"
                    value="yearAgo"
                    (change)="changeSelection($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
            </ul>
          </div>
          <button
            (click)="onApplyFilters()"
            type="submit"
            class="primaryBtn Btn my-4"
          >
            Apply filters
          </button>
        </div>
      </div>
      <ng-template #noProducts> No transactions available </ng-template>
      <div *ngIf="filteredProductList?.length; else noProducts">
        <div
          class="row points-card"
          *ngFor="let product of filteredProductList"
        >
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img
                height="38"
                *ngIf="product?.image == 'null'"
                src="../../../assets/images/image (3).png"
              />
              <img
                *ngIf="product?.image != 'null'"
                height="38"
                [src]="product.image"
              />
            </div>
          </div>
          <div class="col-6 pl20">
            <h4 class="fs16 mb-1">Reward Points</h4>
            <p class="status fs12 mb-1" [ngStyle]="{ color: product?.color }">
              {{ product.status }}
            </p>
            <p class="fs13 color-lightgrey mb-0">
              {{ product.name }}
            </p>
            <a
              class="font-blue fs13 mt-2"
              *ngIf="product?.status == 'PENDING RECEIPT REQUEST'"
              (click)="onclickUploadReceipt(product?.id)"
              >VALIDATE
            </a>
          </div>
          <div class="col-4 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">{{ product.submissionDate }}</p>
            <h4 class="fs16 color-green fW600">{{ product.points }}</h4>
          </div>
        </div>
        <!-- <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/pediasure.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Points</h4>
            <p class="status color-red fs12 mb-1">INVALID</p>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">Today</p>
          </div>
        </div> -->
        <!-- <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/pediasure.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Points</h4>
            <p class="status color-lightgrey fs12 mb-1">
              PENDING RECEIPT REQUEST
            </p>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
            <a
              href="#"
              class="font-blue fs13 mt-2"
              [routerLink]="['/scan/camera']"
              >VALIDATE</a
            >
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">Yesterday</p>
          </div>
        </div> -->
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="points-used"
      role="tabpanel"
      aria-labelledby="points-used"
    >
      <div class="dropdown d-flex justify-content-end mb-3">
        <a
          class="font-blue text-no-underline"
          href="#"
          role="button"
          id="dropdownMenuLink-2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img class="v-unset" src="../../../../assets/images/filter.png" />
          FILTER
        </a>

        <div
          class="dropdown-menu w-100 border-0"
          aria-labelledby="dropdownMenuLink-2"
        >
          <span
            class="exit"
            *ngIf="checkedStatusListVochers?.length > 0"
            (click)="onClickExitFilterVocher()"
            ><img
              class="mr3"
              src="../../../../assets/images/Group 5085.svg"
            />EXIT FILTER</span
          >
          <div class="filterBySection">
            <p>Filter by date</p>
            <ul>
              <li>
                Last 30 days
                <span>
                  <input
                    value="30Days"
                    class="check4"
                    (change)="changeSelectionVochers($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
              <li>
                Last 3 months
                <span>
                  <input
                    class="check4"
                    value="90Days"
                    (change)="changeSelectionVochers($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
              <li>
                Last year
                <span>
                  <input
                    class="check4"
                    value="yearAgo"
                    (change)="changeSelectionVochers($event)"
                    type="checkbox"
                    name="filter-date"
                    for="filter-date"
                  />
                </span>
              </li>
            </ul>
          </div>
          <button
            class="primaryBtn Btn my-4"
            type="submit"
            (click)="onApplyVochers()"
          >
            Apply filters
          </button>
        </div>
      </div>
      <ng-template #noVochers> No transactions available </ng-template>

      <div *ngIf="filteredVochersList?.length; else noVochers">
        <div class="row points-card" *ngFor="let vocher of filteredVochersList">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img
                *ngIf="vocher?.image == 'null'"
                src="../../../../assets/images/gift-blue.png"
              />
              <img *ngIf="vocher?.image != 'null'" [src]="vocher.image" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Redemption</h4>
            <p class="fs13 color-lightgrey mb-0">{{ vocher.name }}</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">{{ vocher.date }}</p>
            <h4 class="fs16 color-red fW600">-{{ vocher.points }} pts</h4>
          </div>
        </div>
        <!-- <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/gift-blue.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Redemption</h4>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">Today</p>
            <h4 class="fs16 color-red fW600">-20 pts</h4>
          </div>
        </div>
        <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/gift-blue.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Redemption</h4>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">Today</p>
            <h4 class="fs16 color-red fW600">-20 pts</h4>
          </div>
        </div>
        <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/gift-blue.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Redemption</h4>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">Today</p>
            <h4 class="fs16 color-red fW600">-20 pts</h4>
          </div>
        </div>
        <div class="row points-card">
          <div class="col-2 justify-content-center pr-0">
            <div class="earned-logo">
              <img src="../../../../assets/images/gift-blue.png" />
            </div>
          </div>
          <div class="col-7 pl20">
            <h4 class="fs16 mb-1">Reward Redemption</h4>
            <p class="fs13 color-lightgrey mb-0">20 points PediaSure<sup>®</sup>  400g</p>
          </div>
          <div class="col-3 justify-content-end text-right">
            <p class="f13 color-lightgrey mb-2">01/01/2022</p>
            <h4 class="fs16 color-red fW600">-20 pts</h4>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
