<section class="globalContainer bg-lighBlue pb70">
  <div class="dashboard-container pb70">
    <div class="dasboard-profile-card pb-4 rewards-profileBg">
      <div class="row mx-0 px30">
        <div class="col-6 point-text pl-0">
          <p class="fs20 fW600 mt-3">Rewards</p>
        </div>
        <div class="col-6 text-right pr-0">
          <p class="point-text">Total Reward Points</p>
          <p class="points">{{ loyaltyPoints }}</p>
        </div>
      </div>
    </div>
    <div class="rewards-landing my-4 px30 pb-5">
      <p class="color-black fs20">Select {{productName}}<sup>®</sup> product to redeem</p>
      <div *ngFor="let product of productList; let i = index">
        <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
          <div class="col-4 pl-0">
            <div class="reward-points text-center">
              <p class="color-white m-0">{{ product.intouchPoints }} pts</p>
            </div>
            <div class="brand-image text-center">
              <!-- <img
                src="../../../../assets/images/pediasure-brand.png"
                alt="Brand Image"
              /> -->
              <img [src]="product?.imageUrl" alt="Brand Image" />
            </div>
          </div>
          <div class="col-8 brand-qty">
            <h4 class="fs16 font-darkblue mb-3 fW600">{{ product.name }}</h4>
            <div class="quantity">
              <p class="fs12 color-lightgrey mb-2">Quantity</p>
              <div class="position-relative">
                <input
                  disabled
                  type="number"
                  [(ngModel)]="product.quantity"
                  value="{{ product.quantity }}"
                  name="qty"
                  class="w-100 fs13 qty"
                />
                <img
                  class="increment"
                  (click)="onIncrement(i)"
                  src="../../../../assets/images/arrow-up.png"
                />
                <img
                  class="decrement"
                  [class.disabled]="product.disabled === true"
                  (click)="onDecrement(i)"
                  src="../../../../assets/images/arrow-down.png"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <button
              class="primaryBtn Btn btn-white fs13 fW500 hl35"
              (click)="onClickGetReward(product)"
              type="button"
            >
              Get this reward
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
        <div class="col-4 pl-0">
          <div class="reward-points text-center">
            <p class="color-white m-0">232 pts</p>
          </div>
          <div class="brand-image text-center">
            <img
              src="../../../../assets/images/pediasure-brand.png"
              alt="Brand Image"
            />
          </div>
        </div>
        <div class="col-8 brand-qty">
          <h4 class="fs16 font-blue mb-3">PediaSure<sup>®</sup>   GOLD Vanilla 850g</h4>
          <div class="quantity">
            <p class="fs12 color-lightgrey mb-2">Quantity</p>
            <div class="position-relative">
              <input
                type="number"
                value="1"
                name="qty"
                class="w-100 fs13 qty"
              />
              <img
                class="increment"
                src="../../../../assets/images/arrow-up.png"
              />
              <img
                class="decrement"
                src="../../../../assets/images/arrow-down.png"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <button
            class="primaryBtn Btn btn-white fs13 fW500 hl35"
            [routerLink]="['/rewards/checkout']"
            type="button"
          >
            Get this reward
          </button>
        </div>
      </div> -->
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
