<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer bg-lightBlue">
  <div class="product-detail-page px15">
    <div class="bannner-section">
      <div class="imgSection">
        <img src="../../../assets/images/prodimg/PediaSure-ML2-Vanilla-Can-850g-new.png" />
      </div>
      <div class="titleSection v-top">
        <h2 class="fs20 color-yellow mb-0">PediaSure<sup>®</sup> GOLD with Peptigro</h2>
        <span class="fs13">1 - 10 years old</span>
      </div>
    </div>
    <div class="product-detail-container bg-white btl20 btr20 pt-4 pb70">
      <div class="content-card">
        <h4 class="fs20 font-darkblue mt30 mb-3">
          Complete, Balanced nutrition to support growth and development.
        </h4>
        <p class="mb-3 text-justify">
                Scientifically designed to support catch-up growth with its <strong>high in energy</strong> formula, PediaSure<sup>®</sup> Gold is <strong>power-packed with 34 key nutrients</strong> and <strong>delivers complete, balanced nutrition with every serve</strong>, to help <strong>plug nutrition gaps and support growth and development</strong>. It also contains Arginine and Natural Vitamin K2.​
        </p>
        <p class="text-justify">
          Improved PediaSure<sup>®</sup> Gold is now newly enhanced with the unique PeptiGro<sup>TM</sup> nutrient system, containing ingredients that support absorption of key nutrients.
        </p>
        <p class="text-justify">PediaSure<sup>®</sup> Gold is also lactose & gluten free. </p>
        <h4 class="fs20 font-darkblue mt-5 mb-3">Product information</h4>
        <p class="mb-3">NEW TRIPLESURE<sup>TM</sup> SYSTEM WITH UNIQUE PEPTIGRO<sup>TM</sup> NUTRIENT SYSTEM</p>
        <div class="row mb-3 imgIco">
          <div class="col-3 text-center">
            <img class="peptigro-icon"
              src="../../../assets/images/peptigro-icon.png"
              alt="peptigro"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">PEPTIGRO<sup>TM</sup>  Nutrient system to support nutrient absorption</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >With casein phosphopeptides (CPPs). CPPs are predigested smaller protein molecules made from casein (milk protein).
                </span>
              </li>
              <li>
                <span
                  >Vitamin C enhances absorption of iron.​
                </span>
              </li>
              <li>
                <span
                  >Vitamin D helps support calcium absorption and improves bone health.
                </span>
              </li>
              <li>
                <span
                  >Contains Probiotic (L. acidophilus) and Prebiotic (FOS). Prebiotics help maintain a healthy digestive system.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img
              src="../../../assets/images/Physical-growth_tcm150-91131.jpg"
              alt="Growth"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Physical Growth</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Protein provides amino acids needed for muscle tissue
                  building and growth. Arginine is an amino acid.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img
              src="../../../assets/images/Immunity-strength_tcm150-91132.jpg"
              alt="Immunity"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Immunity Support:</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Vitamins A, B6, B12, C, iron, zinc and selenium for normal
                  immune function</span
                >
              </li>
              <!-- <li>
                <span
                  >100% daily requirement of Vitamin D; supports Calcium
                  absorption & improves bone strength</span
                >
              </li>
              <li>
                <span
                  >Triple Protein Complex (Soy, Whey & Casein); 25% more
                  high-quality protein that provides essential amino acids to
                  support muscle tissue building and growth</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img
              src="../../../assets/images/Bone-muscle-development_tcm150-91130.jpg"
              alt="Growth"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Bone Development</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Calcium, vitamins D and K for development of strong
                  bones</span
                >
              </li>
              <!-- <li><span>Vitamin C and Iron to help reduce fatigue</span></li>
              <li>
                <span
                  >Vitamins A, B6, B12, C, Iron, Zinc & Selenium for normal
                  immune function</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <p class="m-3">
          PediaSure<sup>®</sup>  Gold is lactose-free and gluten-free.<br />
          ^ As compared to previous formulation
        </p>
        <h4 class="fs20 font-darkblue mt-5 mb-4">Nutritional Table</h4>
        <div class="table-section">
          <table class="nutrition-table mb-4">
            <thead>
              <tr>
                <th>Nutrient</th>
                <th>Powder per 100g</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Energy (Kcal)</td>
                <td>464</td>
              </tr>
              <tr>
                <td>Protein (g)</td>
                <td>13.87</td>
              </tr>
              <tr>
                <td>Fat (g)</td>
                <td>18.12</td>
              </tr>
              <tr>
                <td class="pl-4">Saturated fatty acids (g)</td>
                <td>4.05</td>
              </tr>
              <tr>
                <td class="pl-4">Polyunsaturated fatty acids (g)</td>
                <td>5.39</td>
              </tr>
              <tr>
                <td class="pl45">Linoleic acid (g)</td>
                <td>4.17</td>
              </tr>
              <tr>
                <td class="pl45">α-Linolenic acid (g)</td>
                <td>0.47</td>
              </tr>
              <tr>
                <td class="pl45">DHA (mg)</td>
                <td>21</td>
              </tr>

              <tr>
                <td>Total Carbohydrate (g)</td>
                <td>62.75</td>
              </tr>
              <tr>
                <td class="pl-4">Total sugar (g)</td>
                <td>17.90</td>
              </tr>
              <tr>
                <td class="pl45">Lactose (g)</td>
                <td>1.53</td>
              </tr>
              <tr>
                <td>FOS (g)</td>
                <td>2.06</td>
              </tr>
              <tr>
                <td>Casein phosphopeptides (mg)</td>
                <td>203</td>
              </tr>
              <tr>
                <td>Taurine (mg)</td>
                <td>33.3</td>
              </tr>
              <tr>
                <td>Carnitine (mg)</td>
                <td>7.9</td>
              </tr>
              <tr>
                <td>Arginine (mg)</td>
                <td>1029</td>
              </tr>
              <tr>
                <td>Inositol (mg)</td>
                <td>37.0</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Vitamins</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vitamin A (IU)</td>
                <td>926</td>
              </tr>
              <tr>
                <td>Vitamin D3 (IU)</td>
                <td>370</td>
              </tr>
              <tr>
                <td>Vitamin E (IU)</td>
                <td>10.7</td>
              </tr>
              <tr>
                <td>Vitamin K (total) (mcg)</td>
                <td>35.5</td>
              </tr>
              <tr>
                <td class="pl-4">Vitamin K1 (phylloquinone) (mcg)</td>
                <td>27.3</td>
              </tr>
              <tr>
                <td class="pl-4">Vitamin K2 (menaquinone) (mcg)</td>
                <td>8.2</td>
              </tr>
              <tr>
                <td>Vitamin C (ascorbic acid)(mg)</td>
                <td>51.0</td>
              </tr>
              <tr>
                <td>Vitamin E (mg α-TE)</td>
                <td>7.2</td>
              </tr>
              <tr>
                <td>Folic acid (mcg)</td>
                <td>119</td>
              </tr>
              <tr>
                <td>Vitamin B1 (thiamin) (mg)</td>
                <td>1.12</td>
              </tr>
              <tr>
                <td>Vitamin B2 (riboflavin) (mg)</td>
                <td>1.05</td>
              </tr>
              <tr>
                <td>Vitamin B6 (pyridoxine) (mg)</td>
                <td>1.21</td>
              </tr>
              <tr>
                <td>Vitamin B12 (mcg)</td>
                <td>1.39</td>
              </tr>
              <tr>
                <td>Niacin (mg)</td>
                <td>6.94</td>
              </tr>
              <tr>
                <td>Pantothenic acid (mg)</td>
                <td>3.50</td>
              </tr>
              <tr>
                <td>Biotin (mcg)</td>
                <td>10.0</td>
              </tr>
              <tr>
                <td>Choline (mg)</td>
                <td>145.0</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Minerals</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sodium (mg)</td>
                <td>176</td>
              </tr>
              <tr>
                <td>Potassium (mg)</td>
                <td>606</td>
              </tr>
              <tr>
                <td>Chloride (mg)</td>
                <td>469</td>
              </tr>
              <tr>
                <td>Calcium (mg)</td>
                <td>579</td>
              </tr>
              <tr>
                <td>Phosphorus (mg)</td>
                <td>388</td>
              </tr>
              <tr>
                <td>Magnesium (mg)</td>
                <td>91.7</td>
              </tr>
              <tr>
                <td>Iron (mg)</td>
                <td>6.48</td>
              </tr>
              <tr>
                <td>Zinc (mg)</td>
                <td>3.23</td>
              </tr>
              <tr>
                <td>Manganese (mg)</td>
                <td>0.69</td>
              </tr>

              <tr>
                <td>Copper (mg)</td>
                <td>0.30</td>
              </tr>
              <tr>
                <td>Iodine (mcg)</td>
                <td>44.9</td>
              </tr>
              <tr>
                <td>Selenium (mcg)</td>
                <td>14.8</td>
              </tr>
              <tr>
                <td>Chromium (mcg)</td>
                <td>13.9</td>
              </tr>

              <tr>
                <td>Molybdenum (mcg)</td>
                <td>24.0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="arrow-up-fixed">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
