<app-header title="Log in"></app-header>

<section class="globalContainer bg-white">
  <div class="block-notice text-center">
    <h5 class="font-red mb-3 fs20">NOTICE</h5>
    <p class="fs16">
      Your account is temporarily blocked as the system has detected suspicious
      activities. Please contact Abbott Family Careline at
      <span class="font-blue fW600">6278 6220</span>.
    </p>
    <div class="btnSection mt50">
      <button type="button" class="primaryBtn" [routerLink]="['/login']">
        Close
      </button>
    </div>
  </div>
</section>
