import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  behavsubject = new BehaviorSubject(<boolean>false);
  salesforceCheck = new BehaviorSubject(<boolean>false);
  // loggerApi(formData) {
  //   return this.http.post(env.EMAIL_CHECK_API + '/mobile/log', formData);
  // }

  productinfo(
    scanCode: any,
    latitude: any,
    longitude: any,
    confidence: any
  ) {
    let headers = new HttpHeaders({
      'country-code': env.COUNTRY_CODE,
      language: env.LANGUAGE,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.EMAIL_CHECK_API +
        `/sg/productinfo?scan_code=${scanCode}&longitude=${longitude}&latitude=${latitude}&confidence=${confidence}`,
      {
        headers: headers,
      }
    );
  }

  tokenGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/token/generate',
      formData,
      { headers: headers }
    );
  }

  tokenGenerateSocialLogin(formData: any, token: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CAP-API-OAUTH-TOKEN': token,
    });
    return this.http.post(
      env.SOCIAL_LOGIN_URL + '/auth/v1/token/generate',
      formData,
      { headers: headers }
    );
  }

  passwordValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/validate',
      formData,
      { headers: headers }
    );
  }

  forgotPassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/forget',
      formData,
      { headers: headers }
    );
  }

  otpGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/otp/generate',
      formData,
      { headers: headers }
    );
  }

  otpValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/otp/validate',
      formData,
      { headers: headers }
    );
  }

  tokenReGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/token/regenerate',
      formData,
      { headers: headers }
    );
  }
  getCapCustomer(mobileNo: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + '/customer/get?user_id=true&expiry_schedule=true',
      {
        headers: headers,
      }
    );
  }

  getCapCustomerPromotion(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
        `/customer/get?mobile=${mobileNo}&format=json&promotion_points=True&user_id=true`,
      { headers: headers }
    );
  }

  getSalesForceData(mobile: string) {
    let headers = new HttpHeaders({
      'country-code': 'SG',
      language: 'en_us',
    });
    return this.http.get(
      env.SALESFORCE_BASE_URL + `/salesforce/contact?mobile=${mobile}`,
      { headers: headers }
    );
  }
  getPrivacyPolicyData() {
    return this.http.get(env.CMS_URL + '/privacy');
  }

  getTermsandCondition() {
    return this.http.get(env.CMS_URL + '/tnc');
  }

  addCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    console.log(mobileNo, 'mobileNo---');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/customer/add', formData, {
      headers: headers,
    });
  }

  updateCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    console.log(mobileNo, 'mobileNo===');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/customer/update', formData, {
      headers: headers,
    });
  }

  updateCapCustomerIdentity(
    formData: any,
    mobileNo: string,
    getAuthKey: string
  ) {
    console.log(mobileNo, 'mobileNo===');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.API_BASE_URL + '/customer/update_identity',
      formData,
      { headers: headers }
    );
  }

  getTraceability(scanCode: any) {
    sessionStorage.setItem('scannedCode', scanCode);
    console.log(sessionStorage.getItem('scannedCode'), 'scan code');
    // nfQzC4mkNF1e
    let headers = new HttpHeaders({
      'country-code': 'SG',
      language: 'en_US',
    });
    return this.http.get(
      `${env.API_URl_TRACEBILITY}/v1/traceability/scancode?scan_code=${scanCode}`,
      { headers: headers }
    );
  }
  getMarvalRewardDetails(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    console.log('ff', headers);

    return this.http.get(
      env.API_BASE_URL + `/marvel/reward/${env.rewarddetail}?language=en`,
      { headers: headers }
    );
  }
  getMarvalRewardCategories(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(env.API_BASE_URL + '/marvel/rewards/categories', {
      headers: headers,
    });
  }
  //Spin wheel
  getCustomerCoupons(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(env.API_BASE_URL + '/customer/coupons', {
      headers: headers,
    });
  }

  gamePlay(mobileNo: string, getAuthKey: string, id: any) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + `/games/play?mobile=${mobileNo}&id=${id}`,
      { headers: headers }
    );
  }

  sttarterGetGameplayReward(id: any) {
    return this.http.get(
      env.STTARTER_GAMEPLAY_REWARD + `/giftallocations?productcode=${id}`
    );
    //return this.http.get(env.STTARTER_GAMEPLAY_REWARD+`/giftallocation`);
  }
  sttarterFirsttimeScanGameId() {
    return this.http.get(env.STTARTER_GAMEPLAY_FIRSTTIME_SCAN);
  }
  sttarterLuckyDrawGameId() {
    return this.http.get(env.STTARTER_GAMEPLAY_LUCKY_DRAW);
  }


  updateTrans(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'SG',
      language: 'en_US',
    });
    return this.http.put(
      env.SALESFORCE_BASE_URL + '/transaction/update',
      formData,
      { headers: headers }
    );
  }
  getCoupons(coupon_code, mobileNo, getAuthKey) {
    let headers = new HttpHeaders({
      Authorization:
        'Basic ' + btoa(`${env.TILL_ID}:${Md5.hashStr('abbott123')}`),
    });
    // &format=json
    return this.http.get(
      env.GAME_MAP_API + `/coupon/get?code=${coupon_code}&format=json`,
      { headers: headers }
    );
  }

  getCouponsSeriesCode(id, mobileNo, getAuthKey) {
    let headers = new HttpHeaders({
      Authorization:
        'Basic ' + btoa(`${env.TILL_ID}:${Md5.hashStr('abbott123')}`),
    });
    return this.http.get(
      env.GAME_MAP_API + `/coupon/series?format=json&id=${id}`,
      { headers: headers }
    );
  }
  gameAllocate(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/game/allocate', formData, {
      headers: headers,
    });
  }

  pointsRedeem(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/points/redeem', formData, {
      headers: headers,
    });
  }
  similacTermsandConditions() {
    return this.http.get(env.STTARTER_GAMEPLAY_REWARD + `/contentblocks`);
  }
  getTransaction(mobileNo, getAuthKey, start_date, end_date) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
        `/customer/transactions/get?start_date=${start_date}&end_date=${end_date}&limit=100`,
      { headers: headers }
    );
  }

  //Marval Reward Issue
  marvalRewardIssue(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    //return this.http.post(env.API_BASE_URL+`/marvel/rewards/issue?id=${id}&till_id=${env.TILL_ID}`,formData,{headers:headers});
    return this.http.post(
      env.API_BASE_URL + `/bulk/reward/issue?till_id=${env.TILL_ID}`,
      formData,
      { headers: headers }
    );
  }

  getCustomerRedemption(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(env.API_BASE_URL + '/customer/redemptions', {
      headers: headers,
    });
  }

  addTransaction(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'SG',
      language: 'en_US',
    });
    console.log(headers, 'headers');
    return this.http.post(
      env.SALESFORCE_BASE_URL + '/earnpoints/scancode',
      formData,
      { headers: headers }
    );
  }

  postGpassEarnPoints(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'SG',
      language: 'en_US',
    });
    return this.http.post(env.SALESFORCE_BASE_URL + '/sg/scancode', formData, {
      headers: headers,
    });
  }

  // Coupon Reedem
  postCouponRedeem(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/coupon/redeem', formData, {
      headers: headers,
    });
  }

  //  Get Current location
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (resp) => {
            resolve({
              lng: resp.coords.longitude,
              lat: resp.coords.latitude,
              accuracy: Math.floor(resp.coords.accuracy),
            });
          },
          (err) => {
            resolve({ lng: 'undefined', lat: 'undefined' });
          },
          {
            timeout: 10000,
          }
        );
      }
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    let saveMyPassword = sessionStorage.getItem('saveMyPassword');
    let saveMobile = sessionStorage.getItem('savedMobileNumber');
    let savePassword = sessionStorage.getItem('savedPassword');
    sessionStorage.clear();
    if (saveMyPassword == 'true') {
      sessionStorage.setItem('saveMyPassword', saveMyPassword);
      sessionStorage.setItem('savedMobileNumber', saveMobile);
      sessionStorage.setItem('savedPassword', savePassword);
    }

    this.router.navigate(['/login']);
  }

  // Toaster Service
  showToaster(msg: any) {
    this.toastr.info(msg);
  }
  showToasterError(msg: any) {
    this.toastr.error(msg, 'Major Error', {
      timeOut: 3000,
    });
  }
  showToasterGeo(msg: any) {
    this.toastr.info(msg);
  }

  getSavedImage(
    mobileNo: string,
    getAuthKey: string,
    uniqueId: string,
    format: any
  ) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + `/image?filename=${env.ORG_ID}/${uniqueId}${format}`,
      { headers: headers }
    );
  }

  getSavedImageFinal(mobileNo: string, getAuthKey: string, uniqueId: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(env.API_BASE_URL + `/image?fileName=${env.ORG_ID}/${uniqueId}`, {
      headers: headers,
      
    });
  }

  customerImageUpload(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'SG',
      language: 'en_US',
    });
    return this.http.post(env.SALESFORCE_BASE_URL + '/image/upload', formData, {
      headers: headers,
    });
  }

  checkExistingEmail(email: any) {
    let headers = new HttpHeaders({
      'country-code': 'SG',
      language: 'en_US',
    });
    return this.http.get(
      env.EMAIL_CHECK_API + `/email/validate?email=${email}`,
      { headers: headers }
    );
  }

  setRememberUser(value) {
    this.behavsubject.next(value);
  }

  getProfileObs(): Observable<any> {
    return this.profileObs$.asObservable();
  }

  setProfileObs(profile: any) {
    this.profileObs$.next(profile);
  }

  setSalesForceUser(value) {
    this.salesforceCheck.next(value);
  }

  changePassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/change',
      formData,
      { headers: headers }
    );
  }

  getTermsConditionAbout() {
    return this.http.get(env.CMS_URL + '/termsandconditions');
  }

  getFaq() {
    return this.http.get(env.CMS_URL + '/faq');
  }

  getcontentBlocks() {
    return this.http.get(env.CMS_URL + '/contentblocks');
  }

  getGpasProductImage(sku) {
    return this.http.get(env.CMS_URL + `/gpasproductimages?productsku=${sku}`);
  }

  getSkuPoints(sku) {
    return this.http.get(env.CMS_URL + `/skupoints?sku=${sku}`);
  }

  getPostalCode(postalcode){
    return this.http.get(env.CMS_URL + `/restricteddeliveryarea?pincode=${postalcode}`);
  }

  getPoints(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + '/points/history?mlp=true&type=points',
      { headers: headers }
    );
  }

  getProductsList(mobile: any) {
    let headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this.http.get(
      `${env.EMAIL_CHECK_API}/sg/admin/productlist/status?mobile=${mobile}`,
      { headers: headers }
    );
  }

  getPointsList(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + `/points/history?mlp=true&type=points`,
      { headers: headers }
    );
  }

  getCapCustomerPromotionFilter(
    mobileNo: string,
    getAuthKey: string,
    start_date,
    end_date
  ) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
        `/customer/get?mobile=${mobileNo}&format=json&promotion_points=True&user_id=true&start_date=${start_date}&end_date=${end_date}`,
      { headers: headers }
    );
  }

  buzzbeetokengen(formData: any) {
    return this.http.post(
      `${env.SALESFORCE_BASE_URL}/token/generate`,
      formData
    );
  }

  redirectUrlApi(uaaid) {
    return this.http.get(
      env.SALESFORCE_BASE_URL + `/redirectiondata?uuid=${uaaid}`
    );
  }

  oAuthTokenGen(formData) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(env.OAUTH_TOKEN + '/oauth/token/generate', formData, {
      headers: headers,
    });
  }

  getSocialLoginUser(id: any) {
    return this.http.get(
      `${env.SALESFORCE_BASE_URL}/social/login?social_id=${id}`
    );
  }

  postSocialLoginUser(formData: any) {
    return this.http.post(`${env.SALESFORCE_BASE_URL}/social/login`, formData);
  }

  getMarvalVouchers(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
        `/marvel/points/history?till_id=${env.TILL_ID}&status=Active`,
      { headers: headers }
    );
  }
}
