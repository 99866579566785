<section class="globalContainer pt-5 bg-Blue ">
    <div class="comingSoonContainer mt-15vh px15">
        <div class="comingSoonCard text-center px15 text-white">
            <h3>Coming Soon</h3>
            <p>
                Thank you for your interest. Want to be informed when we launch? Please
                send us your cemail and we'll let you know.
            </p>

            <form [formGroup]="emailForm" (ngSubmit)="onSubmitEmailForm()" class="formContainer">
                <div class="field">
                    <input type="email" formControlName="email" placeholder="Email" />
                </div>

                <div *ngIf="submittedEmailForm && emailFormVal.email.errors" class="error">
                    <span *ngIf="emailFormVal.email.errors.required">Email is required</span>
                    <span *ngIf="emailFormVal.email.errors.email">Invalid Email</span>
                </div>

                <div class="field">
                    <button type="submit" class="primaryBtn Btn btn-white">Submit</button>
                </div>
            </form>
        </div>
    </div>
    <div class="abbott-rewards">
        <div class="rewards-inner">
            <img src="../assets/images/rewards.png" />
            <div class="reward-content">
                <p>* Abbott Family Rewards program is restricted to consumers residing in Singapore only.</p>
            </div>
        </div>
    </div>
</section>