import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from '../services/api.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
   title = 'Abbott-SG';
  submittedEmailForm = false;
  emailForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService, 
    private route: ActivatedRoute,
    private bugsnagService: BugsnagService) {
      this.spinner.hide();
     }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  get emailFormVal() {
    return this.emailForm.controls;
  }

  onSubmitEmailForm() {
    this.submittedEmailForm = true
    if (this.emailForm.invalid) {
      return
    } else {
      console.log(this.emailForm.value)
      let formData = {
        "email": this.emailForm.value.email
      }
      this.spinner.show();
      this.apiService.addUser(formData).pipe(first())
        .subscribe((res: any) => {
          // this.successBtn = true;
          // this.initialBtn = false;
          alert(res?.status)
          this.spinner.hide();
          console.log(res, '======= resp =====');
        }, err => {
          console.log(err, '=== error list =======')
          this.spinner.hide();
          this.bugsnagService.notifyError(err, formData);
        });
    }
  }

}
