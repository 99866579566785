<section class="globalContainer px-5 py-4 bg-white">
  <div class="disclaimer mt-15vh text-center">
    <img src="../../../assets/images/points-added.png" alt="Points added" />
    <h4 class="font-darkblue mb-1 fs32 mt-3">+50 POINTS</h4>
    <p class="mb-4">added to your account!</p>
    <h4 class="fs20 font-darkblue mt50">Account created!</h4>
    <p class="mb-5">
      Welcome to Abbott Family Rewards Program. Start scanning your purchases to
      accumulate rewards points.
    </p>
    <div class="btnSection" *ngIf="isScanCodeAvailable && invalidQR == 'false'">
      <button type="button" class="primaryBtn" (click)="onClickContinue()">
        Continue
      </button>
    </div>
    <div class="btnSection" *ngIf="isScanCodeAvailable && invalidQR == 'true'">
      <button type="button" class="primaryBtn" (click)="onClickScanNow()">
        Scan QR code to earn now!
      </button>
      <button
        type="button"
        class="primaryBtn btn-white mt-2"
        (click)="onClickDashboard()"
      >
        Proceed to dashboard
      </button>
    </div>
    <div class="btnSection" *ngIf="!isScanCodeAvailable || invalidQR == null">
      <button type="button" class="primaryBtn" (click)="onClickScanNow()">
        Scan QR code to earn now!
      </button>
      <button
        type="button"
        class="primaryBtn btn-white mt-2"
        (click)="onClickDashboard()"
      >
        Proceed to dashboard
      </button>
    </div>
  </div>
</section>
