import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../../../src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submittedChangePasswordForm: boolean = false;
  showCurrentPasswordText: boolean = false;
  showEyeCloseCurrentIcon: boolean = true;
  showNewPasswordText: boolean = false;
  showEyeCloseIcon: boolean = true;
  showResendOtp: boolean = false;
  showEyeOpenIcon: any = false;
  showEyeOpenCurrentIcon: any = false;
  showConfirmPasswordtext: any = false;
  showEyeCloseIconConfirm: boolean = true;
  showEyeOpenIconConfirm: boolean = false;
  sessionId: any;
  showOtpForm: boolean = false;
  showChangePassword: boolean = true
  registerOtpForm: FormGroup;
  invalidOtp: boolean;
  submitRegOtpForm: boolean = false;
  otp: any;
  mobileNo: string;
  beginTimer: boolean;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  timeLeft: any;
  otpColor: string;
  public barLabel: string = "Password strength:";
  public strengthLabels = ['', 'WEAK', 'NORMAL', 'STRONG', 'GREAT!'];
  public thresholds = [90, 75, 45, 25];
  strength: number;
  customerName: string;
  passwordNotMaching: boolean = false;
  currentPasswordNotMatching: boolean = false;
  isPasswordPatternCorrect: boolean = false;
  checkPasswordMatch: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private bugsnagService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
        // Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]],
      newPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
        //Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]
      ],
      confirmNewPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
        //  Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]]
    }, {
      validators: MustMatch('newPassword', 'confirmNewPassword')
    })

    this.registerOtpForm = this.formBuilder.group({
      otp1: ['', Validators.required],
    });
    this.getCustomer()
  }

  onPasswordCheckPattern($event) {
    this.isPasswordPatternCorrect = this.changePasswordForm.controls.newPassword.status == 'INVALID' ? true : false
    this.checkPasswordMatch = this.changePasswordForm.controls.currentPassword.value == this.changePasswordForm.controls.newPassword.value
  }

  checkPassword($event) {
  }

  onPasswordConfirmChanged($event) {
    let password = this.changePasswordForm.controls.newPassword.value
    if ($event != password && password.length > 0) this.passwordNotMaching = true
    else this.passwordNotMaching = false
  }

  strengthChanged(strength: number) {
    this.strength = strength;
  }


  get editPasswordFormVal() {
    return this.changePasswordForm.controls;
  }

  onClickShowCurrentPassword() {
    this.showCurrentPasswordText = !this.showCurrentPasswordText;
    this.showEyeCloseCurrentIcon = !this.showEyeCloseCurrentIcon;
    this.showEyeOpenCurrentIcon = !this.showEyeOpenCurrentIcon
  }

  onOtpChange(otp) {
    console.log(otp)
    this.invalidOtp = false;
    this.otp = otp;
  }

  onClickShowNewPassword() {
    this.showNewPasswordText = !this.showNewPasswordText;
    this.showEyeCloseIcon = !this.showEyeCloseIcon;
    this.showEyeOpenIcon = !this.showEyeOpenIcon
  }

  onClickShowConfirmPassword() {
    this.showConfirmPasswordtext = !this.showConfirmPasswordtext
    this.showEyeCloseIconConfirm = !this.showEyeCloseIconConfirm
    this.showEyeOpenIconConfirm = !this.showEyeOpenIconConfirm
  }

  onSubmitChangePasswordForm() {
    this.submittedChangePasswordForm = true;
    if (this.changePasswordForm.invalid || this.currentPasswordNotMatching || this.checkPasswordMatch) {
      return
    } else {
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: getMobile,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: 'Test@123',
        confirmPassword: 'Test@123',
      };
      console.log(getMobile, 'mobile taken from sessionStorage---');
      this.spinner.show()
      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe((res) => {
          this.spinner.hide()
          if (res['status'].code == 200) {
            this.sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
            this.showResendOtp = false;
            //----------forgot password---------
            let formData = {
              identifierType: 'MOBILE',
              identifierValue: getMobile,
              deviceId: env.DEVICE_ID,
              brand: env.BRAND,
              sessionId: this.sessionId,
              password: 'test123',
              confirmPassword: 'test123',
            };
            this.spinner.show()
            this.apiService.forgotPassword(formData)
              .pipe(first()).subscribe(
                (res) => {
                  this.spinner.hide()
                  if (res['status'].code == 200) {
                    let otpGenerateData = {
                      identifierType: 'MOBILE',
                      identifierValue: getMobile,
                      deviceId: env.DEVICE_ID,
                      brand: env.BRAND,
                      sessionId: this.sessionId,
                    };
                    this.spinner.show()
                    this.apiService.otpGenerate(otpGenerateData)
                      .pipe(first()).subscribe(
                        (res => {
                          this.spinner.hide()
                          if (res['status'].code == 200) {
                            // this.forgotModalOne.hide();
                            // open otp form
                            this.showOtpForm = true;
                            this.showChangePassword = false
                          } else {

                          }
                        })
                      ), err => {
                        this.spinner.hide()
                        console.log(err, '=== OTP gererate error list =======');
                        this.bugsnagService.notifyError(err, otpGenerateData);
                      }
                  }
                }
              ),
              err => {
                this.spinner.hide()
                console.log(res['status'].message);
                this.bugsnagService.notifyError(err, formData);
              }
          }
        }),
        err => {
          this.spinner.hide()
          console.log(err);
          this.bugsnagService.notifyError(err, formData);
        }
    }
  }

  onSubmitRegOtpForm() {
    this.submitRegOtpForm = true;
    if (this.otp == undefined || this.otp.length < 4) {
      console.log('otp err');
      return;
    } else {
      this.sessionId = sessionStorage.getItem('sessionID');
      this.mobileNo = sessionStorage.getItem('mobileNo')
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": JSON.parse(this.mobileNo),
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": JSON.parse(this.sessionId),
        "otp": this.otp
      }
      console.log("otpSubmitData: ", otpSubmitData);
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe((res: any) => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            this.showOtpForm = false;
            this.beginTimer = false;
            if (res['status'].code == 200) {
              let token = sessionStorage.getItem('authToken');
              let formData =
              {
                "identifierType": "MOBILE",
                //  "identifierValue": this.NewMobileNo == null ? this.mobileNo : this.NewMobileNo,
                "identifierValue": this.mobileNo,
                "deviceId": env.DEVICE_ID,
                "brand": env.BRAND,
                "password": 'test123',
                "newPassword": this.changePasswordForm.value.newPassword,
                "confirmPassword": this.changePasswordForm.value.confirmNewPassword,
                "token": token
              }
              this.spinner.show();
              this.apiService.changePassword(formData).pipe(first())
                .subscribe(res => {
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    this.beginTimer = false;
                    console.log("RES: ", res);
                    this.router.navigate(['/profile'])
                    this.changePasswordForm.reset()
                  }
                }), err => {
                  console.log(err);
                  this.bugsnagService.notifyError(err, formData);
                }
            }
          } else {
            this.invalidOtp = true;
          }
        }), err => {
          console.log(err);
          this.bugsnagService.notifyError(err, otpSubmitData);
        }
    }
  }

  changeCurrentPassword($event) {
    let currentValue = $event.target.value
    let password = sessionStorage.getItem('userPassword')
    this.currentPasswordNotMatching = currentValue != password ? true : false
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft <= 10000) {
      this.otpColor = 'red'
    }
  }


  resendOTP() {
    this.ngOtpInput.setValue('');
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.changePasswordForm.value.newPassword,
      confirmPassword: this.changePasswordForm.value.confirmNewPassword,

    };


    this.spinner.show();
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {

        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              //window.alert('OTP sent');
              this.spinner.hide();
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              console.log(err, '=== OTP gererate error list =======');
              this.bugsnagService.notifyError(err, otpGenerateData);
            }
          );
      }, err => {
        this.bugsnagService.notifyError(err, formData);
      });
  }
  customerData: any;
  loyaltyPoints: any;
  customerNamePresent: any = false;

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log("customer not loggged in")
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName = this.customerData.firstname + " " + this.customerData.lastname
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points
          console.log(this.loyaltyPoints)
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;

        }

      }), err => {
        this.spinner.hide();
        console.log(err)
        this.bugsnagService.notifyError(err);
      }
    }

  }
}
