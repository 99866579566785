import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnInit {
  mobileForm: FormGroup;
  submittedMobileForm: boolean = false;
  userNotRegistered: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bugsnagService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.mobileForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(`^((\\+65-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
          
        ],
      ],
    });
  }

  get mobileFormVal() {
    return this.mobileForm.controls;
  }

  onSubmitMobileForm() {
    this.submittedMobileForm = true;
    if (this.mobileForm.invalid) {
      return;
    } else {
      let mobileNumber = this.mobileForm.value
        ? this.mobileForm.value.mobileNumber
        : '';
      let mobNoStringfy = JSON.stringify(mobileNumber).replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', mobileNumber);
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: mobNoStringfy,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: 'Test@123',
        confirmPassword: 'Test@123',
      };
      this.spinner.show();
      this.apiService
        .tokenGenerate(formData)
        .pipe(first())
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res['status'].code == 200) {
              let sessionId = res['user'].sessionId;
              sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
              if (res['user'].userRegisteredForPassword == false) {
                this.userNotRegistered = true;
                this.router.navigate([
                  'signup',
                  { mobileNumber: mobileNumber },
                ]);
              } else if (res['user'].userRegisteredForPassword == true) {
                this.userNotRegistered = false;
                this.router.navigate(['login', { mobileNumber: mobileNumber }]);
              }
            }
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
            this.bugsnagService.notifyError(err, formData);
          }
        );
    }
  }
}
