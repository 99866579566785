import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { environment as env } from '../../../src/environments/environment';

import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { first } from 'rxjs/operators';
import { BugsnagService } from '../services/bugsnag.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  mobileNo: any;
  imageChangedEvent: any = '';
  showCropper: boolean = false;
  fileInput;
  croppedImage: any = '';
  transform: ImageTransform = {};
  canvasRotation = 0;
  containWithinAspectRatio = false;
  visible = false;
  fileupload: any;
  finalUserImage: any;
  profileImage: any;
  extension: any;
  uniqueId: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bugsnagService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.getCustomer();

  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      if (res['status'].code == 200) {

        this.customerData = res['customers']['customer'][0];
        this.customerName =
          this.customerData?.firstname + ' ' + this.customerData.lastname;
        this.mobileNo = this.customerData?.mobile;
        this.customerNamePresent = true;
        this.loyaltyPoints = this.customerData.loyalty_points;
        console.log(this.loyaltyPoints);
        let profilePic = res['customers']['customer'][0].custom_fields.field;
        let image = profilePic.filter((res) => res.name == 'profileimage');
        this.profileImage = image[0]?.value;
        console.log(this.profileImage)
        this.uniqueId = this.profileImage?.split('.')[0];
        this.extension = this.profileImage?.split("")[this.profileImage.length - 1]

        console.log("unique: ", this.uniqueId)
        console.log("ext: ", this.extension)
        this.getImage(this.uniqueId, this.extension)

        // this.profileImage = "560D4K0PJB5.png"
        // this.spinner.show();
        // this.extension = sessionStorage.getItem('extension')
        // this.uniqueId = sessionStorage.getItem('uniqueId')
        // this.apiService
        //   .getSavedImage(mobile, key, this.uniqueId, this.extension)
        //   .pipe(first())
        //   .subscribe((res: any) => {
        //     this.spinner.hide();
        //     this.finalUserImage = res.file_path;

        //     this.visible = false;
        //   });
      } else {
        this.spinner.hide();
        this.customerNamePresent = false;
      }
    }, err => {
      console.log(err);
      this.spinner.hide()
      this.bugsnagService.notifyError(err);
    });
  }

  getImage(uniqueId, extension) {
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');

    // this.extension = sessionStorage.getItem('extension')
    // this.uniqueId = sessionStorage.getItem('uniqueId')
    this.apiService
      .getSavedImage(getMobile, getAuthKey, uniqueId, extension)
      .pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        this.finalUserImage = res.file_path;

        this.visible = false;
      }, err =>{
        this.bugsnagService.notifyError(err);
      });
  }

  onClickLogout() {
    this.apiService.logout();
    this.router.navigate(['/login-signup']);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.visible = true;
      this.fileupload = event.target.files[0];
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event, 'cropped');
    this.croppedImage = event.base64;
    this.fileInput = base64ToFile(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    this.visible = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  uploadImage() {
    this.visible = false;
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');

    const imageData = new FormData();
    imageData.append('image', this.fileInput);
    imageData.append('mobile', getMobile);
    imageData.append('cap_device_id', env.DEVICE_ID);
    imageData.append('image_custom_field', 'profileimage');

    this.spinner.show();
    this.apiService
      .customerImageUpload(imageData, getAuthKey)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          console.log(res, 'image upload');
          let uniqueId = res.result.image_id;
          let extension = res.result.image_extension;
          console.log(uniqueId, 'uniqueId', extension, 'extension');
          this.spinner.show();
          // this.getImage(uniqueId, extension)
          this.apiService
            .getSavedImage(getMobile, getAuthKey, uniqueId, extension)
            .pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide();
              console.log(res.file_path, 'image get');
              this.finalUserImage = res.file_path;
              this.visible = false;
            }, err =>{
              this.bugsnagService.notifyError(err);
            });
        },
        (err) => {
          if (err) {
            this.spinner.hide();
            this.visible = false;
            this.bugsnagService.notifyError(err, imageData);
          }
        }
      );
  }
}
