<div class="SiteSwitcherContainer">
  <div class="container-bg">
    <div class="container-content">
      <div class="customModal-content">
        <div class="d-flex top-bar pt-5 px20">
          <div class="text-left">
            <!--<img
                  (click)="closeHamberger()"
                  src="./../../../assets/images/ensure/close-hamberger.svg"
                />-->
          </div>
          <div class="text-right">
            <img
              src="./../../../assets/images/ensure/abbott-family-reward.svg"
            />
          </div>
        </div>
        <div class="px20 pt-5">
          <h3 class="text-left mb-3">SELECT BRAND</h3>
          <div class="d-flex brands-list">
            <div>
              <img
                (click)="selectBrand('pediasure')"
                [routerLink]="['/dashboard']"
                src="./../../../assets/images/ensure/brand_pediasure_logo.png"
              />
            </div>
            <div>
              <img
                (click)="selectBrand('ensure')"
                [routerLink]="['/ensure/dashboard']"
                src="./../../../assets/images/ensure/brand_ensure_logo.png"
              />
            </div>
          </div>
          <div class="d-flex brands-list">
            <div>
              <img
                class="glucerna-img"
                src="./../../assets/images/glucerna/coming-soon-new.png"
              />
              <strong class="coming-july">COMING SOON ON 10TH AUGUST</strong>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
