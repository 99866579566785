import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-site-switcher',
  templateUrl: './site-switcher.component.html',
  styleUrls: ['./site-switcher.component.scss'],
})
export class SiteSwitcherComponent implements OnInit {
  brand: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}
  selectBrand(brand) {
    console.log(brand);
    this.brand = brand;
    sessionStorage.setItem('categoryCode', this.brand);
    console.log(sessionStorage.getItem('categoryCode'));
    this.brand = sessionStorage.getItem('categoryCode');
  }
}
