<section class="globalContainer pt-5 bg-Blue">
  <div class="welcome-container">
    <div class="text-center d-flex justify-content-center align-items-center">
      <div class="reward-header">
        <img src="../../assets/images/rewards.png" alt="Rewards" />
      </div>
    </div>
    <div class="welcome-card text-center mt-5">
      <img
        *ngIf="brand == 'Pediasure'"
        src="../../assets/images/pediasure.png"
        alt="PediaSure"
      />
      <img
        *ngIf="brand == 'Ensure'"
        src="../../assets/images/ensure/ensure_logo.svg"
        alt="ensure"
      />
      <p class="font-darkblue fs24 mt-4 mb-1">Thank you for your purchase!</p>
      <p>
        Learn about {{ brand }}<sup>®</sup>
        <a
          [routerLink]="['/dashboard']"
          class="font-blue"
          *ngIf="brand == 'Pediasure'"
          >here</a
        >
        <a
          [routerLink]="['/ensure/dashboard']"
          class="font-blue"
          *ngIf="brand == 'Ensure'"
          >here</a
        >.
      </p>

      <div class="btnSection mt-5">
        <p>
          To start earning points, log in to your <br /><span
            class="font-darkblue"
            >Abbott Family Rewards</span
          >
          account.
        </p>

        <button
          type="submit"
          class="primaryBtn Btn mt-2"
          (click)="onClickLoginOrAcc()"
        >
          Create account / Log in
        </button>
        <button
          type="submit"
          class="primaryBtn mt-2 btn-white Btn"
          routerLink="/about/family"
        >
          Learn about Abbott Family Rewards
        </button>
      </div>
      <p class="terms-text fs13 my-3">
        * Abbott Family Rewards program is restricted to consumers residing in
        Singapore only.
      </p>
    </div>
  </div>
</section>
