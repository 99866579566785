import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = true;
  fileupload: any;
  visible = false;
  now = Date.now();
  url: any;
  productCode: any;
  imageChangedEvent: any = '';
  public errors: WebcamInitError[] = [];

  public webcamImage: any = null;

  private trigger: Subject<void> = new Subject<void>();

  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  fileInput: any;
  showCamera: boolean = true;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private location: Location,
    private bugsnagService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
    this.productCode = this.route.snapshot.params.data;
    console.log(this.productCode, 'productCode====');
  }

  public ngOnInit(): void { }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  onClickBack() {
    this.location.back();
  }

  retake() {
    this.webcamImage = null;
  }

  uploadImage() {
    console.log('uploaded');
    this.visible = false;

    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');

    const imageData = new FormData();
    imageData.append('image', this.webcamImage);
    imageData.append('mobile', getMobile);
    imageData.append('cap_device_id', env.DEVICE_ID);
    imageData.append('image_custom_field', 'product_image');
    imageData.append('id', this.productCode);
    this.spinner.show();
    this.apiService
      .customerImageUpload(imageData, getAuthKey)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          console.log(res, 'image upload');
          let uniqueId = res.result.image_id;
          let extension = res.result.image_extension;
          this.apiService
            .getSavedImage(getMobile, getAuthKey, uniqueId, extension)
            .pipe(first())
            .subscribe((res: any) => {
              console.log(res.file_path, 'image get');
              // this.finalUserImage = res.file_path;
              this.router.navigate(['profile/transaction-history']);
              this.visible = false;
            }, err =>{
              this.bugsnagService.notifyError(err);
            });
        },
        (err) => {
          if (err) {
            this.spinner.hide();
          }
          this.bugsnagService.notifyError(err, imageData);
        }
      );
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.url = this.webcamImage.imageAsDataUrl;
    console.log(this.url, 'this imageurl');
    const url = this.url;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'File.png', { type: 'image/png' });
        console.log(file, 'final url');
        this.webcamImage = file;
        console.log(this.webcamImage, 'final url 22 ====');
        this.showCamera = false;
      });
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed

        this.url = event.target.result;
      };
      this.fileupload = event.target.files[0];
      console.log('th', this.fileupload);
      this.webcamImage = this.fileupload;
      // this.fileInput = base64ToFile(this.webcamImage);
      // console.log("fileinput: ", this.fileInput)
    }
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
