import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  categoryCode: any;
  brand: any
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.categoryCode = sessionStorage.getItem('categoryCode')
    if (this.categoryCode.startsWith('ensure')) this.brand = "Ensure"
    else if (this.categoryCode.startsWith('pediasure')) this.brand = "Pediasure"

  }

  onClickLoginOrAcc() {
    this.router.navigate(['login-signup'])
  }

}
