import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-editaccount',
  templateUrl: './editaccount.component.html',
  styleUrls: ['./editaccount.component.scss']
})
export class EditaccountComponent implements OnInit {
  editAccountForm: FormGroup;
  submittedAccountForm: boolean = false;
  editForm: {
    root: {
      customer: {
        name: any,
        email: any;
      }[];
    };
  };
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  emailAlreadyExists: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bugsnagService: BugsnagService
    ) { }

  ngOnInit(): void {
    this.editAccountForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['']
    })
    this.getCustomer();

  }

  get editAccountFormVal() {
    return this.editAccountForm.controls;
  }

  onSubmitEditAccountForm() {
    this.submittedAccountForm = true;
    if (this.editAccountForm.invalid) {
      return;
    } else {
      let userData = this.editAccountForm.value;
      console.log("userData: ", userData);
      let filledFormValues = this.getuserForm(userData);
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
      if (
        this.customerData?.email !==
        filledFormValues?.root?.customer[0]?.email &&
        filledFormValues?.root?.customer[0]?.email !== ''
      ) {
        this.spinner.show();
        this.apiService
          .checkExistingEmail(encodeURIComponent(userData.email))
          .pipe(first()).subscribe((res: any) => {
            this.spinner.hide();

            if (res.result.statusCode == 200) {
              this.emailAlreadyExists = false
              if (res.result.message === 'User not found') {
                let updateEmail = {
                  root: {
                    customer: [
                      {
                        identifier: 'email',
                        old_value: this.customerData?.email,
                        new_value: userData?.email,
                      },
                    ],
                  },
                };
                this.apiService
                  .updateCapCustomerIdentity(updateEmail, getMobile, token)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      console.log(res['customers'].customer.new_value)
                      this.editAccountForm.patchValue({
                        email: res['customers'].customer.new_value
                      })
                      console.log(res, '=== updated email ===');
                      this.spinner.hide();
                    },
                    (err) => {
                      console.log(err, 'err===');
                      this.spinner.hide()
                      this.bugsnagService.notifyError(err, {updateEmail});
                    }
                  );
              }
            } else if (res.result.statusCode == 420) {
              this.emailAlreadyExists = true;
            }
          }, err => {
            this.emailAlreadyExists = true;
            console.log(err)
            this.bugsnagService.notifyError(err, {email: userData.email});
            return
          })
      }
      this.spinner.show();
      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res['status'].code == 200) {
              console.log('profile updated')
              // this.apiService.showToaster('Profil berhasil diperbarui')
              // this.profileUpdate = true;
              // setTimeout(() => {
              //   this.profileUpdate = false;
              // }, 5000)
            }
          }, err => {
            this.spinner.hide();
            console.log(err)
            this.bugsnagService.notifyError(err, filledFormValues);
          }
        )
    }
  }

  customerNamePresent: any = false;

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log("customer not loggged in")
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName = this.customerData.firstname + " " + this.customerData.lastname
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points
          console.log(this.loyaltyPoints)
          this.editAccountForm.patchValue({
            fullName: this.customerName,
            email: this.customerData?.email
          })
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;

        }

      }), err => {
        this.spinner.hide();
        console.log(err)
        this.bugsnagService.notifyError(err);
      }
    }

  }

  getuserForm(userData: any) {
    return (this.editForm = {
      root: {
        customer: [
          {
            name: userData.fullName,
            email: userData?.email
          },
        ],
      },
    });
  }


}
