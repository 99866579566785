<app-header title="Account"></app-header>
<section class="globalContainer px15 py-4">
  <div class="accountPage px15">
    <div class="profileLinks">
      <!-- <a href="#">Change Mobile Number</a> -->
      <a href="#" [routerLink]="['/profile/edit']">
        <img class="mr10 v-top" src="../../assets/images/edit-acc.png" />
        Edit account information
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a href="#" [routerLink]="['/profile/change-password']">
        <img class="mr10 v-top" src="../../assets/images/change-password.png" />
        Change password
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a href="#" [routerLink]="['/profile/manage-address']">
        <img class="mr10 v-top" src="../../assets/images/edit-address.png" />
        Manage recipient information
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <!-- <a href="#">Delete account</a> -->
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
