<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>About Ensure<sup>®</sup> Life</h1>
  </div>
</div>
<section class="globalContainer">
  <section class="about-ensure min-vh-100 bg">
    <div class="top-div">
      <div class="product-details d-flex mx-10">
        <div class="product-image col-5 position-relative">
          <img
            class="position-absolute"
            src="../../../../assets/images/ensure/Ensure-life-product-img.png"
          />
        </div>
        <div class="product-content col-7">
          <h2>
            Ensure<sup>®</sup> Life
            <span class="color-white"> For Everyday Strength </span>
          </h2>
        </div>
      </div>
    </div>
    <div class="bottom-div">
      <div class="product-description">
        <div class="text-content-dark">
          <h3 class="pt-2 pb-2">
            Complete, Balanced Nutrition For Everyday Strength
          </h3>
          <p>
            <span class="black">Ensure<sup>®</sup> Life</span> comes with a
            special ingredient:
            <span class="blue" (click)="handleHMB()">HMB</span>; plus
            <span class="black">high-quality protein</span> that supports muscle
            tissue building. <br />
            <br />
            Scientifically formulated to deliver complete, balanced nutrition,
            this great-tasting beverage can be used as a supplement with or
            between meals, or as a meal replacement. <br />
            <br />
            With over 45 years of scientific evidence from more than 30 clinical
            studies, Ensure® helps to strengthen your nutritional foundation so
            you can live life to the fullest in the years to come.
          </p>
          <h3 class="pt-5 pb-2">Nutrition For Everyday Strength</h3>
          <p>Complete, Balanced Nutrition, Ensure® Life contains:</p>
          <div class="row pt-2 pb-2">
            <div class="col-3">
              <img
                src="../../../../assets/images/ensure/ensure_contents_strength_icon.png"
              />
            </div>
            <div class="col-9">
              <h4 class="pb-2">
                HMB; plus high-quality protein that supports muscle tissue
                building and growth
              </h4>
              <p>
                • HMB is a metabolite of the essential amino acid leucine
                <br /><br />• HMB is found naturally in small amounts in some
                foods and in the body<br /><br />
                • HMB level in the body may decline with age
              </p>
            </div>
          </div>
          <div class="row pt-2 pb-2">
            <div class="col-3">
              <img
                src="../../../../assets/images/ensure/ensure_contents_validity_icon.png"
              />
            </div>
            <div class="col-9">
              <h4 class="pb-2">34 nutrients including:</h4>
              <p>
                • Vitamin D to improve bone strength and maintain normal muscle
                and immune functions<br /><br />• Calcium & phosphorus for
                strong bones <br /><br />• Vitamins B1, B2, B3 & B6 for energy
                release <br /><br />• Vitamins B12 & C help to reduce tiredness
                and fatigue <br /><br />• Antioxidants (β-carotene, Vitamins
                C,E, zinc & Selenium) help to protect cells in the body
              </p>
            </div>
          </div>
          <div class="row pt-2 pb-2">
            <div class="col-3">
              <img
                src="../../../../assets/images/ensure/ensure_contents_activity_icon.png"
              />
            </div>
            <div class="col-9">
              <p>
                • <span class="blue">Good fat blend</span> with polyunsaturated
                fatty acids (omega 3 & 6), monounsaturated fatty acid (omega 9)
                and low in saturated fat<br /><br />
                • <span class="blue">Prebiotic FOS</span> to promote growth of
                good bacteria and help maintain a healthy digestive system
                <br /><br />•
                <span class="blue"
                  >Cholesterol-free, trans fat-free, lactose free and gluten
                  free</span
                >
              </p>
            </div>
          </div>
          <p class="mt-2 mb-2">
            <span class="black">2 servings of Ensure<sup>®</sup> Life</span>
            provides <span class="black">1.5g HMB</span> plus 21g high quality
            protein.
          </p>

          <img
            class="servings-of-ensure"
            src="../../../../assets/images/ensure/2-servings-of-ensure.png"
          />

          <button
            type="button"
            class="btn btn-primary btn-lg btn-block btn_custom mt-5 mb-2"
            (click)="LearnMoreAboutEnsureBtn()"
          >
            Learn more about Ensure<sup>®</sup>
            <img
              class="btn_arrow_icon"
              src="../../../../assets/images/ensure/btn_arrow_icon.svg"
            />
          </button>
        </div>
      </div>

      <app-scroll-to-top></app-scroll-to-top>
    </div>
  </section>
  <div class="bottom-bg"></div>
</section>

<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
