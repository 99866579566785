import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-initial-dashboard',
  templateUrl: './initial-dashboard.component.html',
  styleUrls: ['./initial-dashboard.component.scss']
})
export class InitialDashboardComponent implements OnInit {
  latitude: string;
  longitude: string;
  confidence: string;
  loader: boolean = true;
  scanCode = '';
  iSprintData: any;
  categoryCode: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router, private spinner: NgxSpinnerService,
    private APIService: ApiService,
    private bugsnagService: BugsnagService
  ) {
    this.getPosition();

    window.scrollTo({ top: 0 })

    if (this.route.snapshot.queryParams.c) {
      this.scanCode = this.route.snapshot.queryParams.c;
      console.log(this.scanCode, 'this.scanCode')
    } else if (this.route.snapshot.queryParams.u) {
      this.scanCode = this.route.snapshot.queryParams.u;
      console.log(this.scanCode, 'scancode')
    }

    if (this.scanCode === undefined ||
      this.scanCode === null || this.scanCode == '') {
      this.loader = false;
      sessionStorage.removeItem('iSprintData');
      this.router.navigate(['site-switcher'])
    }

    sessionStorage.setItem('scannedCode', this.scanCode);
    console.log(sessionStorage.getItem('scannedCode'), 'scan code');

  }

  getPosition() {
    this.APIService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy
      this.latitude = JSON.stringify(lat)
      this.longitude = JSON.stringify(lang)
      this.confidence = JSON.stringify(confidence);
      if (this.loader && this.scanCode) {
        this.getTraceabilityContent();
      }
    }, err => {
      this.bugsnagService.notifyError(err);
    })
  }

  getTraceabilityContent() {
    this.loader = true;
    this.spinner.show();
    this.APIService.productinfo(this.scanCode, this.latitude, this.longitude, this.confidence).subscribe(
      (res: any) => {
        res.status = 'success'
        this.spinner.hide();
        if (res.status === 'success') {
          sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
          this.iSprintData = res.result
          this.categoryCode = this.iSprintData.category_code.toLowerCase()
          sessionStorage.setItem('categoryCode', this.categoryCode)
          sessionStorage.setItem('invalidQR', 'false')
          this.router.navigate(['/welcome'])
        } else {
          this.spinner.hide();
          alert('Please scan a valid QR code.');
        }
      }, err => {
        this.spinner.hide();
        console.log(err.error.message)
        //alert(err.error.message);
        if (err?.error?.code == 400) {
          alert(err?.error?.message)
          sessionStorage.setItem('invalidQR', 'true')
        }
        else if (err?.error?.code == 420) {
          alert(err?.error?.message)
          sessionStorage.setItem('invalidQR', 'true')
        } else {
          console.log(err.error.message);
          sessionStorage.setItem('invalidQR', 'true')
        }
        this.loader = false;
        this.router.navigate(['/welcome'])
        let payload = {
          scanCode: this.scanCode,
          latitude: this.latitude,
          longitude: this.longitude,
          confidence: this.confidence
        }
        this.bugsnagService.notifyError(err, payload);
      }
    )
  }

  ngOnInit(): void {
  }

}
