<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>What is HMB?</h1>
  </div>
</div>
<section class="globalContainer">
  <section class="about-ensure min-vh-100 bg">
    <div class="top-div">
      <div class="product-details d-flex mx-10">
        <div class="product-image col-5 position-relative">
          <img
            class="position-absolute"
            src="../../../../assets/images/ensure/with_HMB.png"
          />
        </div>
        <div class="product-content col-7">
          <h2>
            What is HMB?
            <span class="color-white"> Beta-hydroxy beta-methylbutyrate </span>
          </h2>
        </div>
      </div>
    </div>
    <div class="bottom-div">
      <div class="product-description">
        <div class="text-content-dark">
          <ol>
            <li>HMB is a metabolite of the essential amino acid leucine.</li>
            <li>
              HMB is found naturally in small amounts in our body and some food.
            </li>
            <li>HMB level in the body may decline with age.</li>
            <li>
              <div>
                HMB combined with high quality protein supports muscle tissue
                building and growth.<span class="blue text-decoration-underline"
                  ><sup>2, 5</sup></span
                >
              </div>
            </li>
          </ol>

          <h3 class="pt-2 pb-2">
            The difference between young and ageing muscles
          </h3>
          <div class="d-flex muscle-diff mt-2">
            <div>
              <label>YOUNG MUSCLE</label>
              <img src="../../../../assets/images/ensure/young_muscle.png" />
              <p class="mt-2">
                • The muscle tissues in our body are constantly turning over –
                breaking down and building up <br /><br />• The goal is to
                ensure that muscle breakdown isn't happening faster than muscle
                building <br /><br />• Healthy muscles support mobility,
                balance, posture and even strength and energy
              </p>
            </div>
            <div>
              <label>AGEING MUSCLE</label>
              <img src="../../../../assets/images/ensure/ageing_muscle.png" />

              <p class="mt-2">
                • Muscle breakdown can accelerate with age and balance between
                muscle building and breakdown can shift<br /><br />
                • Age-related muscle loss affects ~1 in 3 adults 50+ y.o.<br /><br />
                • Decline in muscle health can contribute to fatigue, loss of
                strength and energy, and poor mobility
              </p>
            </div>
          </div>
          <h3 class="pt-5 pb-2">
            How does HMB (beta-hydroxy-beta-methylbutyrate) work?
          </h3>
          <p>
            HMB may act as a gateway to help keep your muscles in balance by
            slowing muscle breakdown<span class="blue text-decoration-underline"
              ><sup>1-3</sup></span
            >. <br /><br />In fact, HMB has been shown to help preserve muscle
            mass in healthy older adults<span
              class="blue text-decoration-underline"
              ><sup>4</sup></span
            >.
          </p>

          <h3 class="pt-5 pb-2">How to get HMB?</h3>
          <p>
            Most studies show the recommended daily dose of HMB is 1.5 - 3g per
            day, however it's very difficult to get a significant amount of HMB
            from diet alone<span class="blue text-decoration-underline"
              ><sup>5</sup></span
            >.
          </p>
          <p>To get 1.5g of HMB, one needs to consume:</p>
          <div class="row pt-3 pb-3 how-to-get-hmb align-items-center">
            <div class="col-5 text-center">
              <img src="../../../../assets/images/ensure/3000_avocados.png" />
              <p>~ 3,000 <span>avocados</span></p>
            </div>
            <div class="col-2 text-center">
              <img
                src="../../../../assets/images/ensure/HMB_separater_img.png"
              />
            </div>
            <div class="col-5 text-center">
              <img
                src="../../../../assets/images/ensure/750_catfish_fillets.png"
              />
              <p>~ 750 <span>catfish fillets</span></p>
            </div>
          </div>
          <p class="mt-2 mb-2">
            Getting in the practice of incorporating HMB supplements into your
            diet can support muscle health. Talk to your healthcare provider or
            a dietitian about the suitability of ONS with HMB for your diet.
          </p>
        </div>

        <div class="text-content-light mt-3">
          <span class="text-decoration-underline"><sup>1</sup></span> Nissen SL,
          Abumrad NN. J Nutr Biochem 1997; 8:300-311. <br /><br />
          <span class="text-decoration-underline"><sup>2</sup></span> Wilkinson
          DJ, et al. J Physiol 2013; 591(11):2911-2923.<br /><br />
          <span class="text-decoration-underline"><sup>3</sup></span> Eley HL,
          et al. Am J Physiol Endocrinol Metab 2008; 295: E1409-1416.
          <br /><br />
          <span class="text-decoration-underline"><sup>4</sup></span> Stout JR
          et al. Exp Gerontol. 2013 Nov;48(11):1303-10. 5 Lin Z, et al. Eur
          Geriatr Med 2021;12(2):239-251. <br /><br />
          <span class="text-decoration-underline"><sup>5</sup></span> Lin Z,
          Zhao Y, Chen Q. Eur Geriatr Med. 2021 Apr;12(2):239-251.
        </div>

        <button
          type="button"
          class="btn btn-primary btn-lg btn-block btn_custom mt-5 mb-5"
          (click)="LearnAboutMuscleHealthBtn()"
        >
          Learn more about muscle health
          <img
            class="btn_arrow_icon"
            src="../../../../assets/images/ensure/btn_arrow_icon.svg"
          />
        </button>
      </div>
      <app-scroll-to-top></app-scroll-to-top>
    </div>
  </section>
  <div class="bottom-bg"></div>
</section>

<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
