import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submittedLoginForm: boolean = false;
  showpassword = false;
  userNotRegistered: boolean;
  invalidPassword: boolean;
  showEyeCloseIcon: boolean = true;
  showPasswordText: boolean = false;
  showEyeOpenIcon: boolean = false;
  scanCode: string;
  isScanCodeAvailable: any;
  isParamMobileNumber: any = false;
  paramMobileNumber: any;
  invalidQR: any;
  savedLoginDetails: boolean = false;
  customerData: any;
  customerName: string;
  suspiciousCount: number;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bugsnagService: BugsnagService
  ) {
    this.invalidQR = sessionStorage.getItem('invalidQR');
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(
            `^((\\+65-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$'
          ),
        ],
      ],
      saveMyPassword: [false],
    });
    this.paramMobileNumber = this.route.snapshot.params.mobileNumber;
    console.log('mobile number from route params', this.paramMobileNumber);
    if (this.paramMobileNumber != null) {
      this.isParamMobileNumber = true;
      this.loginForm.patchValue({
        mobileNumber: this.paramMobileNumber,
      });
    }
    // if (sessionStorage.getItem('savedMobileNumber')) {
    //   let savedMobile = sessionStorage.getItem('savedMobileNumber');
    //   this.loginForm.patchValue({
    //     mobileNumber: savedMobile,
    //   });
    // }
    // if (sessionStorage.getItem('savedPassword')) {
    //   let savedPass = sessionStorage.getItem('savedPassword');
    //   this.loginForm.patchValue({
    //     mobileNumber: savedPass,
    //   });
    // }
    let isUserSaved = sessionStorage.getItem('saveMyPassword');
    if (isUserSaved === 'true') {
      let savedMobile = sessionStorage.getItem('savedMobileNumber');
      let savedPass = sessionStorage.getItem('savedPassword');
      console.log(savedMobile, savedPass);
      if (savedMobile == this.paramMobileNumber) {
        this.loginForm.patchValue({
          mobileNumber: savedMobile,
          password: savedPass,
          saveMyPassword: [true],
        });
      }
    }
    console.log(
      this.loginForm.value.saveMyPassword,
      sessionStorage.getItem('saveMyPassword')
    );
  }

  get loginFormVal() {
    return this.loginForm.controls;
  }

  onClickEyeCloseIcon() {
    this.showEyeCloseIcon = false;
    this.showPasswordText = true;
    this.showEyeOpenIcon = true;
  }

  onClickEyeOpenIcon() {
    this.showPasswordText = false;
    this.showEyeCloseIcon = true;
    this.showEyeOpenIcon = false;
  }

  loginSignupnumber() {
    this.router.navigate([
      'login-signup',
      { mobileNumber: this.paramMobileNumber },
    ]);
  }
  onSubmitLoginForm() {
    this.submittedLoginForm = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      let mobileNumber = this.loginForm.value
        ? this.loginForm.value.mobileNumber
        : '';
      let password = this.loginForm.value ? this.loginForm.value.password : '';
      let mob = String(mobileNumber).replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', mob);
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: mob,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: password,
        confirmPassword: password,
      };
      this.spinner.show();
      this.apiService
        .tokenGenerate(formData)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res['status'].code == 200) {
              let sessionId = res['user'].sessionId;
              sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
              if (res['user'].userRegisteredForPassword == false) {
                this.spinner.hide();
                this.router.navigate(['/signup']);
                this.userNotRegistered = true;
              } else if (
                res['user'].userRegisteredForPassword == true ||
                res['status'].code == 1515
              ) {
                let formData = {
                  identifierType: 'MOBILE',
                  identifierValue: mob,
                  deviceId: env.DEVICE_ID,
                  brand: env.BRAND,
                  sessionId: sessionId,
                  password: password,
                };
                this.spinner.show();

                this.apiService
                  .passwordValidate(formData)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      this.spinner.hide();

                      if (res['status'].code == 200) {
                        if(res["user"].userRegisteredForPassword){
                          this.apiService.getCapCustomer(mobileNumber, res['auth'].token).subscribe(customerResponse=>{
                            if(customerResponse['status'].code == 200){

                        let token = res['auth'].token;
                        sessionStorage.setItem('authToken', res['auth'].token);
                        sessionStorage.setItem(
                          'authTokenNew',
                          res['auth'].token
                        );
                        sessionStorage.setItem('authKey', res['auth'].key);
                        sessionStorage.setItem('userPassword', password);
                        sessionStorage.setItem(
                          'saveMyPassword',
                          this.loginForm.value.saveMyPassword
                        );
                        if (this.loginForm.value.saveMyPassword) {
                          sessionStorage.setItem(
                            'savedMobileNumber',
                            this.loginForm.value.mobileNumber
                          );
                          sessionStorage.setItem(
                            'savedPassword',
                            this.loginForm.value.password
                          );
                        }
                        console.log(
                          this.loginForm.value.saveMyPassword,
                          sessionStorage.getItem('saveMyPassword')
                        );

                        let getMobile = sessionStorage.getItem('mobileNo');
                        let getAuthKey = sessionStorage.getItem('authToken');
                        this.scanCode = sessionStorage.getItem('scannedCode');
                        this.isScanCodeAvailable = this.isEmpty(this.scanCode);
                        console.log(this.invalidQR, 'this.invalidQR');
                        console.log(
                          this.isScanCodeAvailable,
                          'this.isScanCodeAvailable'
                        );
                        setTimeout(() => this.getCustomer(), 2000);
                        if (this.invalidQR == null) {
                          this.router.navigate(['site-switcher']);
                        } else if (!this.isScanCodeAvailable) {
                          this.router.navigate(['site-switcher']);
                        }
                        if (
                          this.isScanCodeAvailable &&
                          this.invalidQR == 'false'
                        ) {
                          this.router.navigate(['disclaimer']);
                        } else if (
                          this.isScanCodeAvailable &&
                          this.invalidQR == 'true'
                        ) {
                          this.router.navigate(['site-switcher']);
                        }
                      }else{
                        this.spinner.hide();
                        this.router.navigate(['/signup', { mobileNumber: mobileNumber }]);
                      }
                    }, err=>{
                      this.spinner.hide();
                      this.bugsnagService.notifyError(err);
                    })
                    
                  }else{
                    this.spinner.hide();
                    this.router.navigate(['/signup', { mobileNumber: mobileNumber }]);
                      
                      
                        }
                      
                      
                      } else {
                        console.log('Wrong passwords', res);
                        this.invalidPassword = true;
                        setTimeout(() => {
                          this.invalidPassword = false;
                        }, 5000);
                      }
                    },
                    (err) => {
                      this.bugsnagService.notifyError(err, formData);
                    }
                  );
              } else if (res['status'].code == 1505) {
                this.router.navigate(['/login-signup']);
                console.log(res['status'].message);
              } else if (res['status'].code == 1528) {
                this.spinner.hide();
                console.log(res['status'].message);
                this.invalidPassword = true;
                setTimeout(() => {
                  this.invalidPassword = false;
                }, 5000);
                console.log(this.invalidPassword, 'this.invalidPassword');
              } else {
                this.apiService.showToaster(res['status'].message);
                console.log(res['status'].message);
              }
            } else {
              console.log('user not registered !!!!');
            }
          },
          (err) => {
            this.spinner.hide();
            if (err) {
              this.spinner.hide();
            }
            this.bugsnagService.notifyError(err, formData);
          }
        );
    }
  }
  isEmpty(scanCode) {
    if (scanCode == '' || scanCode == null || scanCode == undefined)
      return false;
    else return true;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        this.spinner.hide();
        this.customerData = res['customers']['customer'][0];
        console.log(this.customerData);
        this.customerName =
          this.customerData.firstname + ' ' + this.customerData.lastname;
        console.log(this.customerName);
        sessionStorage.setItem('customerName', this.customerName);
        this.customerData.custom_fields.field.forEach((element) => {
          if (element.name === 'suspicious_count') {
            this.suspiciousCount = parseInt(element.value);
            sessionStorage.setItem(
              'suspiciousCount',
              JSON.stringify(this.suspiciousCount)
            );
          }
        });
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      }
    );
    console.log(this.suspiciousCount, 'suspiciousCount');
  }
}
