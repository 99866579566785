<app-header title="About program"></app-header>
<section class="termsContainer pb70 px30">
  <h1 class="fs20 font-darkblue mt40">
    Terms and Conditions of Abbott Family Rewards Loyalty Program
  </h1>
  <p>Effective Date: [1 January 2022]</p>
  <ul class="disc-inside px-0 my-4 pb70">
    <li>
      Abbott Family Rewards (AFR) is a loyalty program for
      PediaSure<sup>®</sup>and Ensure<sup>®</sup> Life Powder products
      (Vanilla(850g,400g),Wheat(850g) and Dairy Alternative (850g)) customers.
    </li>

    <li>
      By registering as a member of AFR, you are deemed to have read and
      understood these Terms and Conditions and have given your consent to
      Abbott to process your personal data and disclose the same to third
      parties providing services to Abbott to provide you with the services you
      request and to respond to your questions in relation to AFR. You also give
      your consent to Abbott to communicate with you in one or more of the
      following manners i.e., mail, email, phone, SMS and WhatsApp, to provide
      you with the services you request and to respond to your questions in
      relation to AFR.
    </li>

    <li>All members of AFR are subject to these Terms and Conditions.</li>

    <li>
      Customer who registers as a member of AFR shall be 18 years of age and
      above.
    </li>

    <li>
      At this time, only purchases of PediaSure<sup>®</sup> and
      Ensure<sup>®</sup>Life Powder products (Vanilla(850g,400g),Wheat(850g) and
      Dairy Alternative (850g)) are eligible to participate in AFR.
      Participating products may change from time to time at the discretion of
      Abbott and these Terms and Conditions will be updated accordingly.
    </li>

    <li>
      AFR allows members to accumulate rewards points from purchases of
      PediaSure<sup>®</sup> and Ensure<sup>®</sup>Life Powder products
      (Vanilla(850g,400g),Wheat(850g) and Dairy Alternative (850g)) powder
      products. These points may be used to redeem selected Abbott products
      only. Please refer to the Rewards Catalogue for the list of Abbott
      products which may be redeemed under AFR. Note that the list of products
      available for redemption would be based on stock availability at any point
      in time.
    </li>

    <li>
      Each AFR member is only eligible for one membership and no transfer of
      rewards points or sharing and polling of rewards points with another AFR
      member is allowed.
    </li>

    <li>
      AFR rewards points may be accumulated by scanning QR code on the
      participating Abbott product(s). Note: A successful scan is only
      registered when you are logged in to your user account when scanning the
      QR code on the can. Successfully scanned rewards points will only be
      updated in member’s account within 5 working days.
    </li>

    <li>
      Abbott reserves the right to change/amend rewards points tagged to each
      product without prior notice.
    </li>

    <li>
      All redeemed products will be delivered to members’ provided delivery
      address within 3 weeks post successful redemption. You will receive a
      notification on the day of delivery and you will not be able to request
      for a specific time slot or date.
    </li>

    <li>
      Redemption with incomplete or incorrect information (delivery address,
      contact number) will not be processed. Abbott will not be responsible for
      failed delivery attempts if the address information provided by customer
      is incorrect.
    </li>

    <li>
      All redemptions submitted by members may not be exchanged, cancelled or
      returned.
    </li>

    <li>
      Each AFR reward point is valid for 12 months, after which it will expire
      and will not be valid for use. Point expiry notification will only be
      triggered when there is ≥10 rewards points that is expiring in 14 days.
    </li>

    <li>
      Abbott reserves the right to reject any redemption or request for receipt
      verification to validate the authenticity of the purchase if fraudulent
      activity is suspected.
    </li>

    <li>
      Members are advised to examine the redemption items upon receipt. Should
      you find the good(s) received are faulty, damaged, and / or defective,
      please contact our Abbott Customer Careline at 6278-6220 (Mon to Fri, 9am
      – 6pm) within 24 hours from date of receipt to lodge a report.
    </li>

    <li>
      Abbott reserves the right to amend/terminate AFR program with/without
      prior notice.
    </li>

    <li>
      Abbott has the discretion to amend these Terms and Conditions with/without
      prior notice. If Abbott amends these Terms and Conditions, an updated
      version of these Terms and Conditions will reflect those changes and we
      will notify you of such changes by updating the effective date at the top
      of these Terms and Conditions.
    </li>
  </ul>
  <div class="arrow-up">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>

<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
